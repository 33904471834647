import React from 'react';

function EditCardIcon({ fill, ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="64"
      height="52"
      viewBox="0 0 64 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2 17H62V11.6C62 8.23969 62 6.55953 61.346 5.27606C60.7708 4.14709 59.8529 3.22921 58.7239 2.65397C57.4405 2 55.7603 2 52.4 2H11.6C8.23969 2 6.55953 2 5.27606 2.65396C4.14709 3.2292 3.2292 4.14708 2.65396 5.27606C2 6.55953 2 8.23969 2 11.6V34.4C2 37.7603 2 39.4405 2.65396 40.7239C3.2292 41.8529 4.14708 42.7708 5.27606 43.346C6.55953 44 8.23969 44 11.6 44H29M39.5 50L45.5749 48.785C46.1046 48.6791 46.3694 48.6261 46.6164 48.5292C46.8356 48.4433 47.044 48.3318 47.2371 48.1971C47.4547 48.0453 47.6457 47.8543 48.0277 47.4723L60.5 35C62.1569 33.3431 62.1569 30.6569 60.5 29C58.8431 27.3431 56.1568 27.3431 54.5 29L42.0277 41.4723C41.6457 41.8543 41.4547 42.0453 41.3029 42.2629C41.1682 42.456 41.0567 42.6644 40.9708 42.8836C40.8739 43.1306 40.8209 43.3954 40.715 43.9251L39.5 50Z"
        stroke={fill ?? 'currentColor'}
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default EditCardIcon;
