import { REPORT_PERMISSIONS, USER_ROLE_PERMISSIONS, WORKSPACE_ROLES } from '@/const/workspace';
import { useGetCurrentWorkSpace } from '@/hooks/useSession';

export type UserRoleI = 'OWNER' | 'ADMIN' | 'MAINTAINER' | 'USER';

interface UserPermissionsI {
  role: UserRoleI;
  levelPermissions: number;
}

const LEVEL_ROLE_USER = 0;
const LEVEL_ROLE_MAINTAINER = 1;
const LEVEL_ROLE_ADMIN = 2;
const LEVEL_ROLE_OWNER = 3;
export const USER_PERMISSIONS: UserPermissionsI[] = [
  {
    levelPermissions: LEVEL_ROLE_OWNER,
    role: WORKSPACE_ROLES.OWNER
  },
  {
    levelPermissions: LEVEL_ROLE_ADMIN,
    role: WORKSPACE_ROLES.ADMIN
  },
  {
    levelPermissions: LEVEL_ROLE_MAINTAINER,
    role: WORKSPACE_ROLES.MAINTAINER
  },
  {
    levelPermissions: LEVEL_ROLE_USER,
    role: WORKSPACE_ROLES.USER
  }
];

export const usePermissions = () => {
  const currentWorkSpace = useGetCurrentWorkSpace();

  const getPermissionsForRemoveUser = (deleteUserRole: string) => {
    const deleteFindUserPermissions = USER_PERMISSIONS.find(
      userPer => userPer.role === deleteUserRole
    );
    if (!currentWorkSpace || !deleteFindUserPermissions) {
      return false;
    }
    const currentFindUserPermissions = USER_PERMISSIONS.find(
      userPer => userPer.role === currentWorkSpace?.role
    );
    if (!currentFindUserPermissions) {
      return false;
    }
    const findPermission = currentWorkSpace.permissions.find(
      permission => permission === USER_ROLE_PERMISSIONS.USERS_INVITE
    );
    if (!findPermission) {
      return false;
    }

    return (
      currentFindUserPermissions.levelPermissions >= deleteFindUserPermissions.levelPermissions
    );
  };

  const getPermissionsForAddMembers = () => {
    if (!currentWorkSpace) {
      return false;
    }
    const currentFindUserPermissions = USER_PERMISSIONS.find(
      userPer => userPer.role === currentWorkSpace?.role
    );

    if (!currentFindUserPermissions) {
      return false;
    }
    const findPermission = currentWorkSpace.permissions.find(
      permission => permission === USER_ROLE_PERMISSIONS.USERS_INVITE
    );

    return !!findPermission;
  };

  const getPermissionsForUser = (userRole: string) => {
    const returnDefaultObject = {
      user: false,
      maintainer: false,
      admin: false
    };
    if (!currentWorkSpace) {
      return returnDefaultObject;
    }
    const currentFindUserPermissions = USER_PERMISSIONS.find(
      userPer => userPer.role === currentWorkSpace?.role
    );
    const assignFindUserPermissions = USER_PERMISSIONS.find(userPer => userPer.role === userRole);

    if (!currentFindUserPermissions || !assignFindUserPermissions) {
      return returnDefaultObject;
    }

    if (assignFindUserPermissions.levelPermissions > currentFindUserPermissions.levelPermissions) {
      return returnDefaultObject;
    }

    return {
      user: currentFindUserPermissions.levelPermissions >= LEVEL_ROLE_USER,
      maintainer: currentFindUserPermissions.levelPermissions >= LEVEL_ROLE_MAINTAINER,
      admin: currentFindUserPermissions.levelPermissions >= LEVEL_ROLE_ADMIN
    };
  };

  const getPermissionsForCreateReport = () => {
    if (!currentWorkSpace) {
      return false;
    }
    const findPermission = currentWorkSpace.permissions.find(
      permission => permission === REPORT_PERMISSIONS.REPORT_CREATE
    );

    return !!findPermission;
  };

  return {
    getPermissionsForRemoveUser,
    getPermissionsForAddMembers,
    getPermissionsForUser,
    getPermissionsForCreateReport
  };
};
