import { useMemo, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { checkboxClasses } from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import UserEdit from '@/components/icons/UserEdit';
import EyeIcon from '@/components/icons/Eye';
import Edit2Icon from '@/components/icons/Edit2';
import UsersIcon from '@/components/icons/Users';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { Button, Modal, Paper, Radio, Stack } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { a11yProps } from '@/utils/a11yProps';
import CustomTabPanel from '@/components/CustomTabPanel';
import { TWokspaceRole } from '@/types/workspace';
import { useAlert } from '@/contexts/AlertContext';
import { usePostAssumeRoleMutation } from '@/services/api/workspaces';
import { WORKSPACE_ROLES } from '@/const/workspace';
import { usePermissions } from '@/utils/userPermissions';

const PermissionCheckbox = ({
  label,
  Icon,
  checked,
  setChecked,
  disabled
}: {
  label: string;
  Icon: React.ElementType;
  checked?: boolean;
  setChecked?: (value: boolean) => void;
  disabled?: boolean;
}) => {
  const handleChange = () => {
    setChecked && !disabled && setChecked(!checked);
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        background: checked ? '#D6E9F6' : '#D4D7DB52',
        padding: '6px 16px 6px 16px',
        borderRadius: '12px',
        cursor: 'pointer'
      }}
      onClick={handleChange}
    >
      <FormLabel
        component="legend"
        sx={{ color: checked ? '#0078C8' : '#393A3D', fontWeight: 600, fontSize: '14px' }}
      >
        <Stack direction="row" gap={1} alignItems="center">
          <Icon color={checked ? '#0078C8' : '#B5B7B8'} />
          {label}
        </Stack>
      </FormLabel>
      <FormGroup aria-label="position" row>
        {/* <Checkbox
          checked={checked}
          onChange={handleChange}
          sx={{
            [`&, &.${checkboxClasses.checked}`]: {
              color: '#0078C8'
            }
          }}
        /> */}
        <Radio
          disabled={disabled}
          checked={checked}
          onChange={handleChange}
          sx={{
            [`&, &.${checkboxClasses.checked}`]: {
              color: '#0078C8'
            }
          }}
        />
      </FormGroup>
    </Stack>
  );
};

interface Props {
  workspaceId: number;
  userId: number;
  userRole: string;
}

const PermissionsModal = ({ workspaceId, userId, userRole }: Props) => {
  const [open, setOpen] = useState(false);
  const [selectedTab, setSelectedValue] = useState(0);
  const [company, setCompany] = useState('');
  const [selectRole, setSelectRole] = useState<TWokspaceRole | undefined>();
  const { setAlert } = useAlert();
  const [postAssumeRole] = usePostAssumeRoleMutation();
  const { getPermissionsForUser } = usePermissions();

  const permissionAssign = useMemo(() => {
    return getPermissionsForUser(userRole);
  }, [getPermissionsForUser, userRole]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedValue(newValue);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleChangeCompany = (event: SelectChangeEvent) => {
    setCompany(event.target.value);
  };

  const onHandlerSubmit = async () => {
    if (!selectRole) {
      return;
    }
    const res = await postAssumeRole({
      workspaceId: workspaceId,
      userId: userId,
      role: selectRole
    });
    if ('error' in res) {
      setAlert({
        description: 'User rights have not been changed',
        type: 'error'
      });
    } else {
      setAlert({
        description: 'User rights have been changed',
        type: 'success'
      });
    }

    setTimeout(() => {
      setAlert(undefined);
    }, 5000);

    setOpen(false);
  };

  return (
    <Box sx={{ display: 'flex', flexGrow: 1 }}>
      <Button
        variant="contained"
        color="primary"
        onClick={handleOpen}
        sx={{
          flexGrow: 1,
          padding: '8px 16px 8px 16px',
          textTransform: 'none',
          borderRadius: '6px',
          fontSize: '14px',
          color: '#0078C8',
          fontWeight: 600,
          backgroundColor: '#0078C829',
          '&:hover': { backgroundColor: '#0078C8', color: 'white' }
        }}
      >
        Permissions
      </Button>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            borderRadius: '12px'
          }}
        >
          <Paper elevation={12} sx={{ width: '500px', p: '48px 32px 32px 32px' }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Box
                sx={{
                  color: '#0078C8',
                  textAlign: 'center'
                }}
              >
                <UserEdit fontSize="small" width="60px" height="60px" />
              </Box>
              <Box sx={{ textAlign: 'center' }}>
                <Typography variant="h5">Manage Permissions</Typography>
                <Typography
                  color="textSecondary"
                  sx={{ mt: 1, width: '270px', fontSize: '16px' }}
                  variant="body2"
                >
                  Please configure access types
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                pt: 4,
                justifyContent: 'space-between'
              }}
            >
              <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs value={selectedTab} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="GLObal" {...a11yProps(0)} sx={{ flexGrow: 1 }} />
                    <Tab label="Company" {...a11yProps(1)} sx={{ flexGrow: 1 }} disabled />
                    <Tab label="report" {...a11yProps(2)} sx={{ flexGrow: 1 }} disabled />
                  </Tabs>
                </Box>
                <CustomTabPanel value={selectedTab} index={0}>
                  <Stack gap={2}>
                    <PermissionCheckbox
                      disabled={!permissionAssign?.user}
                      label="User"
                      Icon={EyeIcon}
                      checked={selectRole === WORKSPACE_ROLES.USER}
                      setChecked={() => setSelectRole(WORKSPACE_ROLES.USER)}
                    />
                    <PermissionCheckbox
                      disabled={!permissionAssign?.maintainer}
                      label="Maintainer"
                      Icon={Edit2Icon}
                      checked={selectRole === WORKSPACE_ROLES.MAINTAINER}
                      setChecked={() => setSelectRole(WORKSPACE_ROLES.MAINTAINER)}
                    />
                    <PermissionCheckbox
                      disabled={!permissionAssign?.admin}
                      label="Admin"
                      Icon={UsersIcon}
                      checked={selectRole === WORKSPACE_ROLES.ADMIN}
                      setChecked={() => setSelectRole(WORKSPACE_ROLES.ADMIN)}
                    />
                  </Stack>
                </CustomTabPanel>
                <CustomTabPanel value={selectedTab} index={1}>
                  <Stack gap={2}>
                    <FormControl variant="standard" sx={{ m: 1 }}>
                      <InputLabel id="demo-simple-select-standard-label">Select company</InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        value={company}
                        onChange={handleChangeCompany}
                        label="Select company"
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value={'Company One'}>Company One</MenuItem>
                        <MenuItem value={'Company Two'}>Company Two</MenuItem>
                        <MenuItem value={'Company Three'}>Company Three</MenuItem>
                      </Select>
                    </FormControl>
                    <PermissionCheckbox label="View Only" Icon={EyeIcon} />
                    <PermissionCheckbox label="Can Edit" Icon={Edit2Icon} />
                    <PermissionCheckbox label="Admin" Icon={UsersIcon} />
                  </Stack>
                </CustomTabPanel>
                <CustomTabPanel value={selectedTab} index={2}>
                  <Stack gap={2}>
                    <FormControl variant="standard" sx={{ m: 1 }}>
                      <InputLabel id="demo-simple-select-standard-label">Select report</InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        value={company}
                        onChange={handleChangeCompany}
                        label="Select report"
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value={'Report One'}>Report One</MenuItem>
                        <MenuItem value={'Report Two'}>Report Two</MenuItem>
                        <MenuItem value={'Report Three'}>Report Three</MenuItem>
                      </Select>
                    </FormControl>
                    <PermissionCheckbox label="View Only" Icon={EyeIcon} />
                    <PermissionCheckbox label="Can Edit" Icon={Edit2Icon} />
                    <PermissionCheckbox label="Admin" Icon={UsersIcon} />
                  </Stack>
                </CustomTabPanel>
              </Box>
            </Box>
            <Button
              fullWidth
              variant="contained"
              onClick={onHandlerSubmit}
              sx={{
                padding: '6px 16px 6px 16px',
                textTransform: 'none',
                borderRadius: '12px',
                backgroundColor: '#0078C8',
                fontWeight: 600,
                '&:hover': { backgroundColor: '#0B789B' }
              }}
            >
              Save
            </Button>
          </Paper>
        </Box>
      </Modal>
    </Box>
  );
};

export default PermissionsModal;
