import { SearchIcon } from '@/components/icons';
import { Box, Input, InputAdornment, Stack, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { SystemStyleObject, Theme } from '@mui/system';

type TSearchInput = {
  title?: string;
  inputStyle?: SystemStyleObject<Theme>;
  isNotShowSearchIcon?: boolean;
  type?: string;
  defaultValue?: string;
  onChange?: (value: string) => void;
  isError?: boolean;
  value?: string;
  placeholder?: string;
};

const errorStyle = 'rgb(244, 67, 54)';
const SearchInput: FC<TSearchInput> = ({
  title,
  inputStyle,
  isNotShowSearchIcon = false,
  type = 'search',
  defaultValue = '',
  onChange,
  isError,
  value,
  placeholder
}) => {
  const [searchTerm, setSearchTerm] = useState(defaultValue || '');

  const handleSetSearchTerm = (e: React.ChangeEvent<HTMLInputElement>) => {
    const targetValue = e.target.value;
    setSearchTerm(targetValue);
    onChange && onChange(targetValue);
  };

  return (
    <Stack direction="column" justifyContent="space-between" alignItems="center" gap="0.75rem">
      {title && (
        <Typography
          sx={{
            textAlign: 'start',
            width: '100%',
            fontSize: { xs: '12px', sm: '14px' },
            color: isError ? errorStyle : '#393A3DA3'
          }}
          variant="h5"
        >
          {title}
        </Typography>
      )}

      <Box
        sx={{
          display: 'flex',
          alignItems: 'start',
          gap: 2,
          width: '100%'
        }}
      >
        <Input
          error={isError}
          size="small"
          placeholder={placeholder}
          type={type}
          onChange={handleSetSearchTerm}
          value={value || searchTerm}
          sx={{
            borderBottom: '1px solid #EAECF0',
            width: '50%',
            minWidth: {
              xs: '100%',
              sm: '13rem',
              ...(inputStyle || {})
            }
          }}
          startAdornment={
            !isNotShowSearchIcon && (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            )
          }
        />
      </Box>
    </Stack>
  );
};

export default SearchInput;
