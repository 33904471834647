import { useCallback, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Box, Input, InputAdornment, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';

import { SearchIcon } from '@/components/icons';
import { CombinedTable, ConfirmModal, SquareIconButton, TColumnItem } from '@/components';
import { EReportTableRowActions } from '@/components/reportTableRow/reportTableRow.types';
import { ESearchParam, ESortOrder } from '@/utils/enums';
// import { rowData } from './rowData';
import { MemberTableRow } from './memberTableRow';
import TrashIcon from '@/components/icons/Trash';
import AddMemberModal from './AddMemberModal';
import PermissionsModal from './PermissionsModal';
import {
  useDeleteUserFromWorkspaceMutation,
  useGetWorkspaceQuery
} from '@/services/api/workspaces';
import useSession, { useGetCurrentWorkSpace } from '@/hooks/useSession';
import { ref } from 'yup';
import { usePermissions } from '@/utils/userPermissions';

const pagination = { totalPages: 50, offset: 2, limit: 10, hasMore: false, totalResults: 30 };

const columns: TColumnItem[] = [
  { label: 'Name', accessor: 'name', sortable: true },
  { label: 'Email', accessor: 'email', sortable: true },
  { label: 'Created at', accessor: 'createdAt', sortable: true },
  { label: 'Last activity at', accessor: 'lastActivityAt', sortable: true },
  { label: 'Actions', accessor: '' }
];

const defaultOrder = ESortOrder.ASC;
const defaultSortKey = 'name';

function MembersTable() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOrder] = useState<ESortOrder>(defaultOrder);
  const [sortKey] = useState(defaultSortKey);
  const { currentWorkspaceId, user, workspaces } = useSession();
  const { data: body, isLoading, error, refetch } = useGetWorkspaceQuery(currentWorkspaceId || 0);
  const [onDeleteUserFromWorkspace] = useDeleteUserFromWorkspaceMutation();
  const { getPermissionsForRemoveUser } = usePermissions();

  const members = useMemo(() => {
    return body?.data?.users
      ? body.data.users.map(user => {
          return {
            id: user.id,
            name: `${user.firstName || ''} ${user.lastName || ''}`,
            email: user.email,
            createdAt: '2021-10-01',
            lastActivityAt: '2021-10-01',
            role: user.role
          };
        })
      : [];
  }, [body?.data?.users]);

  const onSearchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const searchTerm = event.target.value;
      searchTerm
        ? searchParams.set(ESearchParam.SEARCH, searchTerm)
        : searchParams.delete(ESearchParam.SEARCH);

      searchParams.delete(ESearchParam.PAGE);
      setSearchParams(searchParams);
      setSearchTerm(searchTerm);
    },
    [searchParams, setSearchParams]
  );

  const onSortOrderChange = useCallback(
    (sortKey: string, sortOrder: ESortOrder) => {
      if (sortKey === defaultSortKey && sortOrder === defaultOrder) {
        searchParams.delete(ESearchParam.SORT_KEY);
        searchParams.delete(ESearchParam.SORT_ORDER);
        setSearchParams(searchParams);
        return;
      }
      searchParams.set(ESearchParam.SORT_KEY, sortKey);
      searchParams.set(ESearchParam.SORT_ORDER, sortOrder);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  const onActionClick = useCallback((itemId: number, action: EReportTableRowActions) => {
    // eslint-disable-next-line no-console
    console.log('onActionClick', itemId, action);
  }, []);

  const onHandlerDeleteUser = async (memberId: number) => {
    await onDeleteUserFromWorkspace({ workspaceId: currentWorkspaceId || 0, userId: memberId });
    await refetch();
  };

  const rows = useMemo(() => {
    return members.map(memberItem => {
      return (
        <MemberTableRow
          notRenderKeys={['role']}
          key={memberItem.id}
          item={memberItem}
          actionsList={
            <Stack direction="row" width="100%" gap={3}>
              <PermissionsModal
                workspaceId={currentWorkspaceId || 0}
                userId={memberItem.id}
                userRole={memberItem.role}
              />
              {getPermissionsForRemoveUser(memberItem.role) && (
                <SquareIconButton
                  key={`${memberItem.id}-${EReportTableRowActions.DELETE}`}
                  onClick={() =>
                    onActionClick && onActionClick(memberItem.id, EReportTableRowActions.DELETE)
                  }
                  sx={{
                    backgroundColor: '#FFBFBF',
                    '&:hover': {
                      backgroundColor: '#f8afaf'
                    }
                  }}
                >
                  <ConfirmModal
                    title={'Are you sure you want to delete this?'}
                    description={
                      'This action will cause deletion of all reports related to this connection.'
                    }
                    context={<TrashIcon fill="#FF6363" />}
                    confirmAction={() => onHandlerDeleteUser(memberItem.id)}
                  />
                </SquareIconButton>
              )}
            </Stack>
          }
          onTableRowClick={() => {}}
        />
      );
    });
  }, [onActionClick, members]);

  const isPaginationDisabled = !members.length || members.length < pagination.limit;

  const TableToolbar = () => {
    return (
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        gap="1rem"
        padding="1.25rem"
      >
        <Typography variant="h5">Members</Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Input
            size="small"
            placeholder="Search..."
            type="search"
            onChange={onSearchChange}
            value={searchTerm}
            sx={{ borderBottom: '1px solid #EAECF0' }}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            }
          />
          <AddMemberModal />
        </Box>
      </Stack>
    );
  };

  return (
    <Box>
      <CombinedTable
        isLoading={isLoading}
        isPaginationDisabled={isPaginationDisabled}
        paginate={false}
        columns={columns}
        rows={rows}
        TableToolbar={<TableToolbar />}
        onSortOrderChange={onSortOrderChange}
        sortKey={sortKey}
        sortOrder={sortOrder}
      />
    </Box>
  );
}

export default MembersTable;
