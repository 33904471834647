import React, { FC, ReactNode, useState } from 'react';
import { Button, Modal, Box, Typography } from '@mui/material';
import { AttentionIcon } from '@/components/icons';

type TConfirmModal = {
  title: string;
  description?: string;
  context: string | JSX.Element | ReactNode;
  closeAction?: () => void;
  confirmAction?: () => void;
};

enum EButtonVariian {
  delete = 'delete',
  cansel = 'cansel'
}

const getButtonStyles = (variant: EButtonVariian) => ({
  width: '100%',
  fontSize: { xs: '12px', sm: '14px' },
  textTransform: 'capitalize',
  fontWeight: '600',
  border: '1px solid transparent',
  color: `${variant === EButtonVariian.delete ? '#FF6363' : '#393A3D'}`,
  backgroundColor: `${variant === EButtonVariian.delete ? '#FFDADA' : '#EAECF0'}`,
  '&:hover': {
    backgroundColor: `${variant === EButtonVariian.delete ? '#FFDADA' : '#EAECF0'}`,
    border: `1px solid ${variant === EButtonVariian.delete ? '#FF6363' : '#393A3D'}`
  }
});

const ConfirmModal: FC<TConfirmModal> = ({
  context,
  title,
  description,
  closeAction,
  confirmAction
}) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handlerDelete = () => {
    setOpen(false);
    confirmAction && confirmAction();

    if (closeAction) {
      closeAction();
    }
  };

  const handleClose = () => {
    setOpen(false);

    if (closeAction) {
      closeAction();
    }
  };

  return (
    <Box sx={{ padding: 0 }} className="FFFFF @22">
      <Button sx={{ padding: 0 }} onClick={handleOpen}>
        {context}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            border: '2px solid transparent',
            borderRadius: '10px',
            boxShadow: 24,
            p: 4,
            maxWidth: '460px',
            width: '90%',
            padding: '2rem'
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '2rem' }}>
            <AttentionIcon />
          </Box>
          <Box
            sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', gap: '1rem' }}
          >
            <Typography
              variant="h3"
              id="modal-modal-title"
              sx={{
                textAlign: 'center',
                fontSize: { xs: '20px', sm: '24px' },
                fontWeight: 'bold',
                padding: '0 2rem'
              }}
            >
              {title}
            </Typography>
            {description && (
              <Typography
                component="p"
                sx={{
                  color: '#393A3D',
                  textAlign: 'center',
                  fontSize: { xs: '14px', sm: '18px' },
                  fontWeight: '400'
                }}
              >
                {description}
              </Typography>
            )}
          </Box>

          <Box
            sx={{
              marginTop: '2rem',
              display: 'flex',
              justifyContent: 'space-between',
              gap: '1rem'
            }}
          >
            <Button
              variant="outlined"
              sx={getButtonStyles(EButtonVariian.delete)}
              onClick={handlerDelete}
            >
              Delete
            </Button>
            <Button
              variant="outlined"
              sx={getButtonStyles(EButtonVariian.cansel)}
              onClick={handleClose}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default ConfirmModal;
