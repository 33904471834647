import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import { Typography } from '@mui/material';

import { usePopover } from '@/hooks/use-popover';
import { MobileNav } from './MobileNav';
import { UserPopover } from './user-popover';
import ChevronDownIcon from '@/components/icons/ChevronDown';
import useSession from '@/hooks/useSession';
import { useCurrentWorkspace } from '@/hooks/useCurrentWorkspace';
import { formatRole } from '@/utils/formatting';
import { WORKSPACE_ROLES } from '@/const/workspace';

type Props = {
  title: string;
};

function MainNav({ title }: Props): React.JSX.Element {
  const { user } = useSession();
  const currentWorkspace = useCurrentWorkspace();
  const [openNav, setOpenNav] = React.useState<boolean>(false);

  const userPopover = usePopover<HTMLDivElement>();

  return (
    <React.Fragment>
      <Box
        component="header"
        sx={{
          borderBottom: '1px solid var(--mui-palette-divider)',
          backgroundColor: 'var(--mui-palette-background-paper)',
          position: 'sticky',
          top: 0,
          zIndex: 'var(--mui-zIndex-appBar)'
        }}
      >
        <Stack
          direction="row"
          spacing={2}
          sx={{
            alignItems: 'center',
            justifyContent: 'space-between',
            minHeight: '64px',
            px: 2
          }}
        >
          <Stack sx={{ alignItems: 'center' }} direction="row" spacing={2}>
            <IconButton
              onClick={(): void => {
                setOpenNav(true);
              }}
              sx={{ display: { lg: 'none' } }}
            >
              <MenuOutlinedIcon />
            </IconButton>
            <Typography variant="h6">{title}</Typography>
          </Stack>
          <Stack sx={{ alignItems: 'center' }} direction="row" spacing={4}>
            {/* <Stack sx={{ alignItems: 'center' }} direction="row" spacing={1}>
              <Box
                sx={{
                  backgroundColor: '#0078C8',
                  borderRadius: '100px',
                  color: 'white',
                  px: '8px',
                  py: '2px'
                }}
              >
                28 days
              </Box>
              <Typography color="#0078C8" sx={{ fontWeight: 600, fontSize: '14px' }}>
                Free Trial
              </Typography>
            </Stack> */}
            <Stack
              sx={{ alignItems: 'center', cursor: 'pointer' }}
              direction="row"
              spacing={2}
              onClick={userPopover.handleOpen}
            >
              <Stack sx={{ position: 'relative' }}>
                <Avatar alt={user?.name || "Remy Sharp"} src={user?.picture} />
                <Box
                  sx={{
                    width: '10px',
                    height: '10px',
                    borderRadius: '50%',
                    backgroundColor: '#0078C8',
                    position: 'absolute',
                    top: 'calc(100% - 10px)',
                    right: 'calc(0% + 2px)',
                    border: '1px solid white'
                  }}
                ></Box>
              </Stack>
              {/* <Avatar src={user.picture} /> */}
              <Stack sx={{ gap: '4px' }}>
                <Typography variant="h6" sx={{ fontSize: '16px' }}>
                  {user?.nickname}
                </Typography>
                <Typography
                  variant="h6"
                  sx={{ fontSize: '12px', fontWeight: 600, color: '#828282' }}
                >
                  {formatRole(currentWorkspace?.role ?? WORKSPACE_ROLES.USER)}
                </Typography>
              </Stack>
              <ChevronDownIcon
                fill="#828282"
                ref={userPopover.anchorRef}
                style={{
                  transform: userPopover.open ? 'rotate(180deg)' : 'none',
                  transition: 'transform 0.3s ease',
                  marginTop: '12px'
                }}
              />
            </Stack>
          </Stack>
        </Stack>
      </Box>
      <UserPopover
        anchorEl={userPopover.anchorRef.current}
        onClose={userPopover.handleClose}
        open={userPopover.open}
      />
      <MobileNav
        onClose={() => {
          setOpenNav(false);
        }}
        open={openNav}
        currentWorkspace={currentWorkspace}
      />
    </React.Fragment>
  );
}

export default MainNav;
