import {
  Box,
  Button,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography
} from '@mui/material';
import { PlanTag } from './planTag';
import { useState } from 'react';
import { UpgradePlanModal } from '@/pages/account/upgradePlanModal';

export const CurrentPlan = () => {
  const [isOpenUpgradePlanModal, setIsOpenUpgradePlanModal] = useState(false);

  return (
    <>
      <Paper
        sx={{
          padding: '24px',
          border: '2px solid #0078C8',
          borderRadius: '12px',
          minWidth: '338px',
          boxShadow: '0px 2px 4px -2px #1018280F, 0px 4px 8px -2px #1018281A',
          fontFamily: 'Manrope'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <PlanTag label="Standard" />
          <Box
            sx={{
              display: 'flex'
            }}
          >
            <Typography
              sx={{
                fontWeight: '600',
                fontSize: '18px'
              }}
            >
              $
            </Typography>
            <Typography
              sx={{
                fontWeight: '700',
                fontSize: '34px',
                lineHeight: '18px',
                margin: '0 3px 0 5px'
              }}
            >
              32
            </Typography>
            <Typography
              sx={{
                fontWeight: '400',
                fontSize: '16px'
              }}
            >
              /month
            </Typography>
          </Box>
        </Box>
        <List
          sx={{
            padding: '32px 0 20px 0'
          }}
        >
          <ListItem
            sx={{
              padding: '0px'
            }}
          >
            <ListItemIcon sx={{ minWidth: '18px' }}>
              <Box
                sx={{
                  width: 8,
                  height: 8,
                  bgcolor: '#C0C0C0',
                  borderRadius: '2px'
                }}
              />
            </ListItemIcon>
            <ListItemText
              sx={{
                fontWeight: '400',
                fontSize: '16px'
              }}
              primary="+ 20 Users for invite"
            />
          </ListItem>
          <ListItem
            sx={{
              padding: '12px 0'
            }}
          >
            <ListItemIcon sx={{ minWidth: '18px' }}>
              <Box
                sx={{
                  width: 8,
                  height: 8,
                  bgcolor: '#C0C0C0',
                  borderRadius: '2px'
                }}
              />
            </ListItemIcon>
            <ListItemText
              sx={{
                fontWeight: '400',
                fontSize: '16px'
              }}
              primary="Up to + 10GB storage"
            />
          </ListItem>
          <ListItem
            sx={{
              padding: '0px'
            }}
          >
            <ListItemIcon sx={{ minWidth: '18px' }}>
              <Box
                sx={{
                  width: 8,
                  height: 8,
                  bgcolor: '#C0C0C0',
                  borderRadius: '2px'
                }}
              />
            </ListItemIcon>
            <ListItemText
              sx={{
                fontWeight: '400',
                fontSize: '16px'
              }}
              primary="Basic Support 24/7"
            />
          </ListItem>
        </List>
        <Box
          sx={{
            marginBottom: '24px'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              fontWeight: '500',
              fontSize: '14px'
            }}
          >
            <Typography>Days</Typography>
            <Typography>23 of 30 days</Typography>
          </Box>
          <LinearProgress
            variant="determinate"
            value={(23 * 100) / 30}
            sx={{
              margin: '8px 0 6px 0',
              height: 8,
              borderRadius: 4,
              backgroundColor: '#e0e0e0', // Колір фону прогрес-бару
              '& .MuiLinearProgress-bar': {
                borderRadius: 4,
                backgroundColor: '#1976d2' // Колір заповненої частини
              }
            }}
          />
          <Typography
            sx={{
              color: '#828282',
              fontSize: '12px',
              fontWeight: '500'
            }}
          >
            7 days remaining
          </Typography>
        </Box>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setIsOpenUpgradePlanModal(true)}
          sx={{
            flexGrow: 1,
            padding: '8px 16px 8px 16px',
            textTransform: 'none',
            borderRadius: '12px',
            fontSize: '14px',
            color: '#0078C8',
            fontWeight: 600,
            backgroundColor: '#0078C829',
            width: '100%',
            '&:hover': { backgroundColor: '#0078C8', color: 'white' }
          }}
        >
          Upgrade plan
        </Button>
      </Paper>
      <UpgradePlanModal
        isOpen={isOpenUpgradePlanModal}
        onClose={() => setIsOpenUpgradePlanModal(false)}
      />
    </>
  );
};
