import { PrimaryButton, SearchInput, SelectInput } from '@/components';
import EditCardIcon from '@/components/icons/EditCardIcon';
import { Box, Button, Modal, Paper, Typography } from '@mui/material';

interface Props {
  onClose: () => void;
  isOpen: boolean;
}
export const EditCardModal = ({ isOpen, onClose }: Props) => {
  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          boxShadow: 24,
          borderRadius: '12px'
        }}
      >
        <Paper sx={{ width: '500px', p: '48px 32px 32px 32px', borderRadius: '12px' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <EditCardIcon fill="#0078C8" />
            <Typography
              sx={{
                color: '#151618',
                fontWeight: 700,
                fontSize: '24px',
                marginTop: '32px'
              }}
            >
              Edit Card
            </Typography>
            <Typography
              sx={{
                color: '#151618',
                fontWeight: 400,
                fontSize: '16px',
                marginTop: '16px'
              }}
            >
              Edit card for future billing
            </Typography>
            <Box
              sx={{
                width: '100%',
                marginTop: '48px',
                display: 'flex',
                flexDirection: 'column',
                gap: '16px'
              }}
            >
              <Box>
                <SearchInput
                  inputStyle={{ width: '100%' }}
                  isNotShowSearchIcon
                  placeholder="4444 4444 4444 4444"
                  title="Card Number"
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  gap: '16px'
                }}
              >
                <SearchInput
                  inputStyle={{ width: '100%' }}
                  isNotShowSearchIcon
                  placeholder="22/11"
                  title="Date"
                />
                <SearchInput
                  inputStyle={{ width: '100%' }}
                  isNotShowSearchIcon
                  placeholder="000"
                  title="CVV"
                />
              </Box>
              <Box>
                <SelectInput
                  id="plan"
                  label="Card Status"
                  placeholder="Primary"
                  options={[]}
                  selectStyle={{
                    paddingLeft: '0px'
                  }}
                />
              </Box>
            </Box>

            <Box
              sx={{
                width: '100%',
                marginTop: '24px',
                display: 'flex',
                gap: '16px'
              }}
            >
              <PrimaryButton
                sx={{ width: '100%' }}
                // onClick={handleOpen}
                label="Submit"
              />
              <Button
                onClick={onClose}
                sx={{
                  width: '100%',
                  height: '44px',
                  backgroundColor: '#D4D7DB52',
                  borderRadius: '12px'
                }}
              >
                <Typography
                  sx={{
                    textTransform: 'none',
                    color: '#393A3D',
                    fontWeight: '600',
                    fontSize: '14px'
                  }}
                >
                  Cancel
                </Typography>
              </Button>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Modal>
  );
};
