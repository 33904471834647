import auth0 from 'auth0-js';

const webAuth = new auth0.WebAuth({
  domain: process.env.REACT_APP_AUTH0_DOMAIN || 'dev-kr0iixh2tgrc2s5m.us.auth0.com',
  clientID: process.env.REACT_APP_AUTH0_CLIENT_ID || '',
  redirectUri: process.env.REACT_APP_AUTH0_CALLBACK_URL,
  audience: process.env.REACT_APP_AUTH0_AUDIENCE,
  scope:
    'openid profile email offline_access create:report create:report-template delete:report delete:report-template read:report read:report-template update:report update:report-template',
  responseType: 'token id_token'
});

export default webAuth;
