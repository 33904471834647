import ReactGA from 'react-ga4';

/**
 * Initialization Google Analytics
 * @param measurementId - your GA4 Measurement ID (e.g. 'G-XXXXXXXXXX')
 */
export const initGA = (measurementId: string) => {
  ReactGA.initialize(measurementId);
};

/**
 * Logging of page views
 * @param page - page path
 */
export const logPageViewAnalytics = (page: string, title?: string) => {
  ReactGA.send({ hitType: 'pageview', page, title });
};

/**
 * Event logging
 * @param category - event category
 * @param action - event action
 * @param label - event label (optional)
 * @param value - event value (optional)
 */
export const logEventAnalytics = (
  category: string,
  action: string,
  label?: string,
  value?: number
) => {
  ReactGA.event({
    category,
    action,
    label,
    value
  });
};
