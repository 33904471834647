import { Link, useNavigate } from 'react-router-dom';
import { Avatar, Box, Link as MUILink, Paper, Stack, TextField, Typography } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { Helmet } from 'react-helmet';
import { paths } from '@/utils/paths';
import { config } from '@/config';
import AuthWrapper from '@/components/auth/AuthWrapper';
import Button from '@/components/buttons/Button';
import uploadIcon from '@/assets/icons/upload.svg';
import { useAuth } from '@/contexts/AuthContext';
import useLocation from '@/hooks/useLocation';
import { FormikHelpers, useFormik } from 'formik';
import { useAlert } from '@/contexts/AlertContext';
import { useEffect, useState } from 'react';

const SignupStep2Page = () => {
  const navigate = useNavigate();
  const { signUp } = useAuth();
  const { state } = useLocation();
  const { setAlert } = useAlert();

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);

  const onDrop = (acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      setSelectedFile(file);

      // Створення прев'ю URL
      const preview = URL.createObjectURL(file);
      setPreviewUrl(preview);
    }
  };

  useEffect(() => {
    // Очищення прев'ю URL при розмонтуванні компонента
    return () => {
      if (previewUrl) {
        URL.revokeObjectURL(previewUrl);
      }
    };
  }, [previewUrl]);

  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    noKeyboard: true,
    accept: {
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/png': ['.png']
    },
    useFsAccessApi: false,
    maxSize: 26214400, // 25MB
    onDrop
  });

  const handleSignUp = async (
    values: { username: string },
    {
      setErrors,
      resetForm
    }: FormikHelpers<{
      username: string;
    }>
  ) => {
    try {
      //   const formData = new FormData();
      //   formData.append('email', state.email);
      //   formData.append('password', state.password);
      //   formData.append('username', values.username);
      //   if (selectedFile) {
      //     formData.append('avatar', selectedFile);
      //   }
      //   await signUp(formData);

      await signUp(state.email, state.password);

      setAlert({
        type: 'success',
        description:
          'A verification email was sent to your email inbox. Please verify your email address.'
      });
      resetForm();
      navigate(paths.signin);
    } catch (error: any) {
      console.error(error);
      setAlert({
        type: 'error',
        description: 'Failed to sign up'
      });
    }
  };

  const formik = useFormik<{ username: string }>({
    validateOnBlur: false,
    initialValues: {
      username: ''
    },
    onSubmit: handleSignUp
  });

  return (
    <Box
      sx={{
        paddingX: { xs: 0, sm: '82px' },
        paddingY: { xs: 2, sm: '72px' }
      }}
    >
      <Box sx={{ mx: { xs: 2, sm: 0 } }}>
        <Typography variant="h5">ClearSheets</Typography>
      </Box>
      <Paper
        sx={{
          maxWidth: '474px',
          mx: { xs: 2, sm: 'auto' },
          my: '50px',
          padding: '48px 32px 32px 32px',
          borderRadius: '12px'
        }}
        elevation={9}
      >
        <Helmet>
          <title>Invitation | {config.site.name}</title>
        </Helmet>
        <Stack gap="32px" sx={{ textAlign: 'center' }}>
          <Stack>
            <Typography
              sx={{ fontSize: { xs: '16px', sm: '24px' }, fontWeight: 700, lineHeight: '30px' }}
              gutterBottom
            >
              Sign up
            </Typography>
            <Typography
              justifyContent={'center'}
              sx={{ fontSize: { xs: '14px', sm: '16px' }, color: '#393A3DA3', fontWeight: 400 }}
            >
              Already have an account?{' '}
              <Link to={paths.auth.signIn} style={{ boxShadow: 'none' }}>
                <MUILink sx={{ textDecoration: 'none', color: '#0078C8', fontWeight: 600 }}>
                  Log in
                </MUILink>
              </Link>
            </Typography>
          </Stack>
          <AuthWrapper isSignUp>
            <form noValidate onSubmit={formik.handleSubmit}>
              <TextField
                variant="standard"
                autoFocus
                fullWidth
                label="User name"
                margin="normal"
                name="username"
                type="username"
                InputProps={{ sx: { borderRadius: '12px' } }}
              />
              {previewUrl ? (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    my: 2
                  }}
                >
                  <Avatar src={previewUrl} alt="Avatar Preview" sx={{ width: 120, height: 120 }} />
                </Box>
              ) : null}
              <Box
                sx={{
                  minWidth: '410px',
                  minHeight: '170px',
                  padding: '16px',
                  borderRadius: '12px',
                  border: '1px solid #EAECF0'
                }}
              >
                <Box
                  sx={{
                    p: '24px',
                    border: '1px dashed #0078C852',
                    borderRadius: '6px',
                    borderWidth: '3px'
                  }}
                  {...getRootProps({})}
                >
                  <input {...getInputProps()} />
                  <img src={uploadIcon} alt="upload" />
                  <Typography
                    sx={{
                      fontSize: { xs: '12px', sm: '14px' },
                      color: '#393A3DA3',
                      fontWeight: 400
                    }}
                  >
                    <Stack direction="row" gap={1}>
                      <Typography sx={{ color: '#0078C8', fontWeight: 600 }}>
                        Click to upload avatar
                      </Typography>
                      <Typography>or drag and drop </Typography>
                    </Stack>
                    <Typography sx={{ fontSize: '12px' }}>max. 25 MB</Typography>
                  </Typography>
                </Box>
              </Box>
              <Button
                type="submit"
                onClick={() => {}}
                title="Sign up"
                backgroundColor="#0078C8"
                styles={{
                  '&:hover': { backgroundColor: '#0B789B' },
                  width: '100%',
                  border: 'solid 1px #12182620',
                  borderRadius: '12px',
                  py: '10px',
                  mt: '24px'
                }}
              />
            </form>
          </AuthWrapper>
        </Stack>
      </Paper>
    </Box>
  );
};

export default SignupStep2Page;
