import { accountingReportsApi } from '@/services';
import { GetUserInfoResponse } from '@/types/user';
import { EUserPaths } from '@/services/enums';

const userApi = accountingReportsApi.injectEndpoints({
  endpoints: builder => ({
    getUserInfo: builder.mutation<GetUserInfoResponse, { accessToken?: string }>({
      query: ({ accessToken }) => {
        const headers = new Headers();

        if (accessToken) {
          headers.set('Access-Control-Allow-Credentials', 'true');
          headers.set('Authorization', `Bearer ${accessToken}`);
        }

        return {
          url: EUserPaths.INFO,
          method: 'GET',
          headers: headers
        };
      }
    }),
    postGoogleConnect: builder.mutation<any, string>({
      query: code => {
        return {
          url: EUserPaths.GOOGLE_CONNECT,
          method: 'POST',
          params: {
            code: code
          }
        };
      }
    })
  })
});

export const { useGetUserInfoMutation, usePostGoogleConnectMutation } = userApi;
