import { PrimaryButton, SelectInput } from '@/components';
import { UpgradePlanIcon } from '@/components/icons/UpgradePlanIcon';
import { Box, Button, Modal, Paper, Typography } from '@mui/material';

interface Props {
  onClose: () => void;
  isOpen: boolean;
}
export const UpgradePlanModal = ({ isOpen, onClose }: Props) => {
  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          boxShadow: 24,
          borderRadius: '12px'
        }}
      >
        <Paper sx={{ width: '500px', p: '48px 32px 32px 32px', borderRadius: '12px' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <UpgradePlanIcon fill="#0078C8" />
            <Typography
              sx={{
                color: '#151618',
                fontWeight: 700,
                fontSize: '24px',
                marginTop: '32px'
              }}
            >
              Upgrade Plan
            </Typography>
            <Typography
              sx={{
                color: '#151618',
                fontWeight: 400,
                fontSize: '16px',
                marginTop: '16px'
              }}
            >
              Choose the best plan for user
            </Typography>
            <Box
              sx={{
                width: '100%',
                marginTop: '48px',
                display: 'flex',
                alignItems: 'flex-end',
                gap: '11px'
              }}
            >
              <Box
                sx={{
                  width: '100%'
                }}
              >
                <SelectInput
                  id="plan"
                  label="Choose plan"
                  placeholder="Standard - $32 / month"
                  options={[]}
                  selectStyle={{
                    paddingLeft: '0px'
                  }}
                />
              </Box>
              <PrimaryButton
                // onClick={handleOpen}
                label="Upgrade"
              />
              {/* <Typography
                sx={{
                  color: '#151618',
                  fontWeight: 700,
                  fontSize: '16px'
                }}
              >
                Choose plan
              </Typography> */}
            </Box>
            <Box
              sx={{
                marginTop: '24px',
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '16px'
                }}
              >
                <Typography
                  sx={{
                    color: '#151618',
                    fontSize: '14px',
                    fontWeight: '500'
                  }}
                >
                  User current plan is standard plan
                </Typography>
                <Box
                  sx={{
                    display: 'flex'
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: '600',
                      fontSize: '18px'
                    }}
                  >
                    $
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: '700',
                      fontSize: '34px',
                      lineHeight: '18px',
                      margin: '0 3px 0 5px'
                    }}
                  >
                    32
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: '400',
                      fontSize: '16px'
                    }}
                  >
                    /month
                  </Typography>
                </Box>
              </Box>
              <Button
                sx={{
                  padding: '9px 31px',
                  height: '44px',
                  backgroundColor: '#FFDADA',
                  border: '1px solid #FFBFBF'
                }}
              >
                <Typography
                  sx={{
                    textTransform: 'none',
                    color: '#FF6363',
                    fontWeight: '600',
                    fontSize: '14px'
                  }}
                >
                  Cancel Subscription
                </Typography>
              </Button>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Modal>
  );
};
