import { FC, useState } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Button, Modal, Paper, Stack } from '@mui/material';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';

import { PrimaryButton } from '@/components';
import LinkIcon from '@/components/icons/Link';
import XeroIcon from '@/components/icons/Xero';
import { logEventAnalytics } from '@/analytics';
import { useConnectToQuickBooks } from '@/hooks/useConnectToQuickBooks';
import QuickBooksButton from '@/components/QuickBooksButton';

const ConnectionsModal: FC = () => {
  const onHandlerConnectQuickbook = useConnectToQuickBooks();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const onHandlerClickConnectQuickbook = async () => {
    logEventAnalytics('Connection', 'Connect Quickbook');
    await onHandlerConnectQuickbook();
  };

  return (
    <Box sx={{ display: 'flex', flexGrow: 1 }}>
      <PrimaryButton
        onClick={handleOpen}
        label="Add new"
        startIcon={<AddOutlinedIcon fontSize="small" />}
      />
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            borderRadius: '12px'
          }}
        >
          <Paper elevation={12} sx={{ width: '500px', p: '48px 32px 32px 32px' }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Box
                sx={{
                  color: '#0078C8',
                  textAlign: 'center'
                }}
              >
                <LinkIcon fontSize="small" width="60px" height="60px" />
              </Box>
              <Box sx={{ textAlign: 'center' }}>
                <Typography variant="h5">Connect your company</Typography>
                <Typography color="textSecondary" sx={{ mt: 1, fontSize: '16px' }} variant="body2">
                  Please select a company to continue
                </Typography>
              </Box>
            </Box>
            <Stack gap={2} sx={{ marginTop: '48px' }}>
              <QuickBooksButton onClick={onHandlerClickConnectQuickbook} />
              <Button
                fullWidth
                variant="contained"
                onClick={() => setOpen(false)}
                sx={{
                  padding: '6px 16px 6px 16px',
                  textTransform: 'none',
                  borderRadius: '12px',
                  height: '44px',
                  backgroundColor: 'rgba(11, 120, 155, 1)',
                  fontWeight: 600,
                  '&:hover': { backgroundColor: 'rgba(11, 120, 155, .8)' },
                  fontSize: '14px',
                  cursor: 'not-allowed'
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    color: 'white',
                    fontSize: '14px',
                    fontWeight: '600'
                  }}
                >
                  <XeroIcon />
                  Connect to Xero
                </Typography>
              </Button>
              <Button
                fullWidth
                variant="outlined"
                onClick={() => setOpen(false)}
                sx={{
                  padding: '6px 16px 6px 16px',
                  textTransform: 'none',
                  borderRadius: '12px',
                  height: '44px',
                  border: '1px solid rgba(234, 236, 240, 1)',
                  fontWeight: 600
                }}
              >
                Cancel
              </Button>
            </Stack>
          </Paper>
        </Box>
      </Modal>
    </Box>
  );
};

export default ConnectionsModal;
