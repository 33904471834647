import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Box, Input, InputAdornment, Typography } from '@mui/material';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import Stack from '@mui/material/Stack';
import { ExelIcon, SearchIcon, SheetsIcon } from '@/components/icons';

import { ReportTableRow, SquareIconButton, TColumnItem } from '@/components';
import EditIcon from '@/components/icons/Edit';
import DownloadIcon from '@/components/icons/Download';
import TrashIcon from '@/components/icons/Trash';
import useDebouncedValue from '@/hooks/useDebouncedValue/useDebouncedValue';
import { HomePage } from '@/pages';
import { ESearchParam, ESortOrder } from '@/utils/enums';
import useGetReports from '../../hooks/reportService/report/useGetReports';
import { useDeleteReportMutation } from '@/services';
import { paths } from '@/utils/paths';
import PrimaryButton from '../../components/ui/primaryButton/PrimaryButton';
import Modal from '@/components/modal/Modal';
import ConfirmDelete from '../../components/modals/confirmDelete/ConfirmDelete';
import { EFORMAT, EFORMAT_API, TReportDb } from '@/entity';
import { ReportDownloadI } from '@/services/api/reportTemplate/types';
import { useGetReportDownloadTemplateQuery } from '@/services/api/reportTemplate';
import useSession from '@/hooks/useSession';
import useGetCompany from '@/hooks/companyService/useCompany';
import EditReportModal from '@/components/modal/EditReport';
import { logEventAnalytics } from '@/analytics';
import { usePermissions } from '@/utils/userPermissions';
import { setInitFlow } from '@/slices';
import { useDispatch } from 'react-redux';
// import { setIsFirstLogin } from '@/slices';

// const pagination = { totalPages: 50, offset: 2, limit: 10, hasMore: false, totalResults: 30 };

const columns: TColumnItem[] = [
  { label: 'Name', accessor: 'name', sortable: true },
  { label: 'Companies', accessor: 'companies', sortable: false },
  { label: 'Format', accessor: 'format', sortable: false },
  { label: 'Interval', accessor: 'schedule-interval', sortable: false },
  { label: 'Created', accessor: 'createdAt', sortable: true },
  { label: 'Last Updated', accessor: 'lastModifiedAt', sortable: true },
  { label: 'Actions', accessor: '' }
];

const defaultOrder = ESortOrder.DESC;
const defaultSortKey = 'createdAt';

const HomePageContainer = () => {
  const navigate = useNavigate();
  const { currentWorkspaceId } = useSession();
  const [deleteReportId, setDeleteReportId] = useState<null | number | string>(null);
  const [editReport, setEditReport] = useState<TReportDb | undefined>();
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearch = useDebouncedValue(searchTerm);
  const [page, setPage] = useState(1);
  const [sortOrder, setSortOrder] = useState<ESortOrder>(defaultOrder);
  const [sortKey, setSortKey] = useState(defaultSortKey);
  const [downloadParams, setDownloadParams] = useState<ReportDownloadI | undefined>();
  const { data: reportTemplateBlob } = useGetReportDownloadTemplateQuery(downloadParams!, {
    skip: !downloadParams
  });
  const dispatch = useDispatch();
  const {
    companies,
    isLoading: isLoadingCompanies,
    refetch: refetchCompanies
  } = useGetCompany({
    page: page - 1,
    size: 10,
    sortOrder,
    sortKey,
    search: undefined
  });

  const { initFlow } = useSession();
  const { getPermissionsForCreateReport } = usePermissions();

  const {
    reports,
    reportsWithoutTranform,
    pagination,
    isLoading,
    refetch: refetchReports
  } = useGetReports({
    page: page - 1,
    size: 10,
    sortOrder,
    sortKey,
    search: debouncedSearch
  });
  const [deleteReport] = useDeleteReportMutation();

  useEffect(() => {
    if (reportTemplateBlob) {
      const url = reportTemplateBlob;
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = `${downloadParams?.name || 'reportTemplate'}.xlsx`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      setDownloadParams(undefined);
    }
  }, [reportTemplateBlob]);

  useEffect(() => {
    refetchReports();
    refetchCompanies();
  }, [currentWorkspaceId]);

  useEffect(() => {
    const searchTerm = searchParams.get(ESearchParam.SEARCH);
    const page = searchParams.get(ESearchParam.PAGE);
    const sortOrder = searchParams.get(ESearchParam.SORT_ORDER);
    const sortKey = searchParams.get(ESearchParam.SORT_KEY);

    const isPageMoreThanOne = page && +page > 1;

    const searchValue = searchTerm || '';
    const pageValue = isPageMoreThanOne ? +page : 1;

    searchValue && searchParams.set(ESearchParam.SEARCH, searchValue);
    isPageMoreThanOne && searchParams.set(ESearchParam.PAGE, pageValue.toString());
    sortOrder && searchParams.set(ESearchParam.SORT_ORDER, sortOrder);
    sortKey && searchParams.set(ESearchParam.SORT_KEY, sortKey);
    setSearchParams(searchParams);

    setSearchTerm(searchValue);
    setPage(pageValue);
    setSortOrder((sortOrder || defaultOrder) as ESortOrder);
    setSortKey(sortKey || defaultSortKey);
  }, [searchParams, setSearchParams]);

  const onSearchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const searchTerm = event.target.value;
      searchTerm
        ? searchParams.set(ESearchParam.SEARCH, searchTerm)
        : searchParams.delete(ESearchParam.SEARCH);

      searchParams.delete(ESearchParam.PAGE);
      setPage(1);
      setSearchParams(searchParams);
      setSearchTerm(searchTerm);
    },
    [searchParams, setSearchParams]
  );

  const handleChangePage = useCallback(
    (newPage: number) => {
      newPage > 1
        ? searchParams.set(ESearchParam.PAGE, newPage.toString())
        : searchParams.delete(ESearchParam.PAGE);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  const handleJumpFirstPage = useCallback(() => {
    searchParams.delete(ESearchParam.PAGE);
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);

  const handleJumpLastPage = useCallback(() => {
    searchParams.set(ESearchParam.PAGE, pagination.totalPages.toString());
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);

  const onSortOrderChange = useCallback(
    (sortKey: string, sortOrder: ESortOrder) => {
      if (sortKey === defaultSortKey && sortOrder === defaultOrder) {
        searchParams.delete(ESearchParam.SORT_KEY);
        searchParams.delete(ESearchParam.SORT_ORDER);
        setSearchParams(searchParams);
        return;
      }
      searchParams.set(ESearchParam.SORT_KEY, sortKey);
      searchParams.set(ESearchParam.SORT_ORDER, sortOrder);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  const onActionBtnClick = useCallback(() => {
    logEventAnalytics('Click', 'Click Button Report');
    navigate(paths.reports.newReport);
  }, []);

  const onModalClose = useCallback(() => {
    setDeleteReportId(null);
  }, []);

  const onModalDelete = useCallback(async () => {
    if (deleteReportId) {
      await deleteReport({ id: deleteReportId });
      refetchReports();
    }
    setDeleteReportId(null);
  }, [deleteReportId]);

  const onModalEditClose = useCallback(() => {
    setEditReport(undefined);
  }, []);

  // const onFinishEdit = () => {
  //   refetch();
  //   setEditReport(undefined);
  // };

  const onFinishEdit = useCallback(() => {
    refetchReports();
    setEditReport(undefined);
  }, []);

  const handleDownload = (report: TReportDb) => {
    const companies = report?.companies;

    if (companies.length) {
      setDownloadParams({
        companyId: companies[0].id,
        platform: companies[0].platform,
        reportTemplateId: report.reportTemplate?.id || 0,
        name: report?.reportTemplate?.name || 'report',
        downloadId: Math.floor(Math.random() * (99999 - 1)) + 1
      });
    }
  };

  useEffect(() => {
    if (isLoadingCompanies) {
      return;
    }

    if (initFlow?.isFirstConnect && !companies.length) {
      navigate('/connect-company');
    } else if (initFlow?.isFirstConnect && companies.length) {
      dispatch(setInitFlow({ isFirstConnect: false, isRedirectAfterConnectToReportCreate: true }));
    }
  }, [isLoadingCompanies, companies]);

  const rows = useMemo(() => {
    // return reports.map(reportItem => { //TODO It is necessary to rozkomite and return this after the API is up and running.
    // const reportsUse = reports.length ? reports : reportsRowData;

    return reports.map(reportItem => {
      const findReport = reportsWithoutTranform.find(report => report.id === reportItem.id);

      return (
        <ReportTableRow
          key={reportItem.id}
          item={reportItem}
          customCell={[
            {
              accessKey: 'format',
              render: (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  {reportItem.format === EFORMAT_API.EXCEL ? (
                    <>
                      <ExelIcon />
                      <Typography variant="subtitle2">{EFORMAT.EXCEL}</Typography>
                    </>
                  ) : (
                    <>
                      <SheetsIcon />
                      <Typography variant="subtitle2">{EFORMAT.GOOGLE_SHEETS}</Typography>
                    </>
                  )}
                </Box>
              )
            }
          ]}
          actionsList={[
            <SquareIconButton
              key="edit"
              disabled={!findReport?.companies.length}
              onClick={() => {
                const findReport = reportsWithoutTranform.find(
                  report => report.id === reportItem.id
                );
                if (findReport) {
                  setEditReport(findReport);
                }
              }}
            >
              <EditIcon />
            </SquareIconButton>
          ]}
          actionsMenuList={[
            <SquareIconButton
              key="download"
              disabled={!findReport?.companies.length}
              onClick={event => {
                if (findReport) {
                  handleDownload(findReport);
                }
              }}
            >
              <DownloadIcon />
            </SquareIconButton>,
            <SquareIconButton
              variant="danger"
              key="delete"
              onClick={() => setDeleteReportId(reportItem.id)}
            >
              <TrashIcon fill="#FF6363" />
            </SquareIconButton>
          ]}
          onTableRowClick={id => console.log(id)}
        />
      );
    });
  }, [reports]);

  const isPaginationDisabled = useMemo(() => {
    return pagination.totalPages <= 1;
    // return !reports.length || reports.length < pagination.limit;
  }, [reports?.length, pagination?.limit]);

  return (
    <>
      <HomePage
        isLoading={isLoading} //TODO uncomit this when api start work
        isPaginationDisabled={isPaginationDisabled}
        columns={columns}
        rows={rows}
        TableToolbar={
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            gap="1rem"
            padding="1.25rem"
          >
            <Typography variant="h5">Reports</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <Input
                size="small"
                placeholder="Search..."
                type="search"
                onChange={onSearchChange}
                value={searchTerm}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
              />
              <PrimaryButton
                label="Create new"
                startIcon={<AddOutlinedIcon fontSize="small" />}
                onClick={onActionBtnClick}
                disabled={!getPermissionsForCreateReport()}
              />
            </Box>
          </Stack>
        }
        onSortOrderChange={onSortOrderChange}
        sortKey={sortKey}
        sortOrder={sortOrder}
        paginationProps={{
          count: pagination.totalPages,
          onPageChange: handleChangePage,
          page,
          onJumpToFirstPage: handleJumpFirstPage,
          onJumpToLastPage: handleJumpLastPage
        }}
      />
      <Modal open={!!deleteReportId?.toString()} onClose={onModalClose}>
        <ConfirmDelete
          title="Are you sure you want to delete this?"
          description="This action will cause deletion of all reports related to this connection."
          confirmLabel="Delete"
          cancelLabel="Cancel"
          onCancel={onModalClose}
          onConfirm={onModalDelete}
        />
      </Modal>

      <Modal
        open={!!editReport}
        onClose={onModalEditClose}
        sx={{
          '& > .MuiDialog-container > .MuiPaper-root': {
            overflowX: 'hidden'
          }
        }}
      >
        <EditReportModal
          onClose={onModalEditClose}
          report={editReport}
          onFinishEdit={onFinishEdit}
        />
      </Modal>
    </>
  );
};

export default HomePageContainer;
