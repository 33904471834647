import { SelectReportMethodForm } from '@/components/selectReportingMethod/SelectReportingMethod';
import {
  ReportTemplateApiTab,
  ReportTemplateContent,
  TemplateParameter
} from '@/services/api/reportTemplates/types';
import { ParameterValueApply, TemplateParameterWithValue } from '@/components/parameterValues/type';
import { OnFinishData } from '@/components/report/type';

export const mapOnFinishRepostTemplateData = (
  reportingMethod?: SelectReportMethodForm,
  options?: {
    template?: ReportTemplateContent;
    parameterValues?: ParameterValueApply;
    parameterTabValues?: ReportTemplateApiTab[];
  }
): OnFinishData => {
  const { template, parameterValues, parameterTabValues } = options || {};
  return {
    parameterValue: parameterValues,
    template,
    reportingMethod,
    parameterTabValues: parameterTabValues
  };
};

export const mapParameterWithValuesToRecords = (
  parameterValues: TemplateParameterWithValue[]
): Record<string, TemplateParameterWithValue> => {
  return parameterValues.reduce(
    (acc: Record<number, TemplateParameterWithValue>, parameter: TemplateParameterWithValue) => ({
      ...acc,
      [parameter.key]: parameter
    }),
    {}
  );
};

export const prepareTemplateParameters = (
  templateParameters: TemplateParameter[],
  values?: TemplateParameterWithValue[]
): Record<string, TemplateParameterWithValue> => {
  const parametersWithValue: TemplateParameterWithValue[] = templateParameters.map(parameter => {
    const value =
      values?.find(value => value.key === parameter.key)?.value ??
      parameter.valueOptions[0].value ??
      '';

    return {
      ...parameter,
      value
    };
  });

  return mapParameterWithValuesToRecords(parametersWithValue);
};
