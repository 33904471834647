import { CombinedTable, ReportTableRow, SearchIcon, TColumnItem } from '@/components';
import TrashIcon from '@/components/icons/Trash';
import { Box, Button, Input, InputAdornment, Stack, Typography } from '@mui/material';
import { useMemo } from 'react';

const columns: TColumnItem[] = [
  { label: 'Amount', accessor: 'amount', sortable: true },
  { label: 'Date', accessor: 'date', sortable: true },
  {
    label: 'Actions',
    accessor: '',
    props: {
      width: 200
    }
  }
];

export const PreviousChargeTable = () => {
  const previousCharges = [
    {
      id: 1,
      amount: '$32.00',
      date: '18.12.00'
    }
  ];

  const rows = useMemo(() => {
    return previousCharges.map(previousCharge => {
      // const findReport = reportsWithoutTranform.find(report => report.id === reportItem.id);

      return (
        <ReportTableRow
          key={previousCharge.id}
          item={previousCharge as any}
          // customCell={[
          //   {
          //     accessKey: 'format',
          //     render: (
          //       <Box sx={{ display: 'flex', alignItems: 'center' }}>
          //         {reportItem.format === EFORMAT_API.EXCEL ? (
          //           <>
          //             <ExelIcon />
          //             <Typography variant="subtitle2">{EFORMAT.EXCEL}</Typography>
          //           </>
          //         ) : (
          //           <>
          //             <SheetsIcon />
          //             <Typography variant="subtitle2">{EFORMAT.GOOGLE_SHEETS}</Typography>
          //           </>
          //         )}
          //       </Box>
          //     )
          //   }
          // ]}
          actionsList={[
            <Button
              sx={{
                width: '116px',
                height: '44px',
                backgroundColor: '#FFDADA',
                border: '1px solid #FFBFBF',
                display: 'flex',
                justifyContent: 'space-around'
              }}
            >
              <TrashIcon fill="#FF6363" />
              <Typography
                sx={{
                  textTransform: 'none',
                  color: '#FF6363',
                  fontWeight: '600',
                  fontSize: '14px'
                }}
              >
                Delete
              </Typography>
            </Button>
          ]}
          onTableRowClick={id => console.log(id)}
        />
      );
    });
  }, []);

  const TableToolbar = () => {
    return (
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        gap="1rem"
        padding="1.25rem"
      >
        <Typography variant="h5">Previous charges</Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Input
            size="small"
            placeholder="Search..."
            type="search"
            // onChange={onSearchChange}
            // value={searchTerm}
            sx={{ borderBottom: '1px solid #EAECF0' }}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            }
          />
        </Box>
      </Stack>
    );
  };

  return (
    <CombinedTable
      isLoading={false}
      isPaginationDisabled={true}
      paginate={false}
      columns={columns}
      rows={rows}
      TableToolbar={<TableToolbar />}
      // onSortOrderChange={onSortOrderChange}
      // sortKey={sortKey}
      // sortOrder={sortOrder}
    />
  );
};
