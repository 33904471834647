import { Stack } from '@mui/material';

// import TeamNameCard from './TeamNameCard';
// import TeamDescriptionCard from './TeamDescriptionCard';
import MembersTable from './MembersTable';
import { useEffect } from 'react';
import { logPageViewAnalytics } from '@/analytics';

function TeamPage(): React.JSX.Element {
  useEffect(() => {
    logPageViewAnalytics(window.location.pathname || '/dashboard/teams', 'Team');
  }, []);

  return (
    <Stack>
      {/* <Stack direction="row" gap={4} height={240}>
        <TeamNameCard />
        <TeamDescriptionCard />
      </Stack> */}
      <MembersTable />
    </Stack>
  );
}

export default TeamPage;
