import { Theme } from '@/styles/theme/types';
import { Box, SxProps } from '@mui/material';

export const PlanTag = ({
  label,
  style = {}
}: {
  label: string;
  style?: SxProps<any> | undefined;
}) => {
  return (
    <Box
      sx={{
        display: 'flex'
      }}
    >
      <Box
        sx={{
          padding: '3px 14px',
          borderRadius: '100px',
          backgroundColor: '#EAECF066',
          color: '#151618',
          fontSize: '12px',
          fontWeight: '500',
          ...style
        }}
      >
        {label}
      </Box>
    </Box>
  );
};
