import { ButttonProps } from '@/components/buttons/type';
import Box from '@mui/material/Box';
import { Button as MuiButton } from '@mui/material';
import Typography from '@mui/material/Typography';

const Button = ({
  title,
  icon,
  onClick,
  styles,
  titleStyles,
  color,
  backgroundColor,
  isFullWidth,
  titleVariant,
  type,
  disabled
}: ButttonProps) => {
  return (
    <Box
      sx={{
        width: isFullWidth ? '100%' : 'auto'
      }}
    >
      <MuiButton
        type={type}
        disabled={disabled}
        fullWidth={isFullWidth}
        onClick={onClick}
        sx={{
          backgroundColor: backgroundColor || '#0078C8',
          outline: 'none',
          padding: '5px 14px',
          borderRadius: '8px',
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
          '&:hover': {
            backgroundColor: backgroundColor || '#0078C8',
            color: color || '#FFFFFF'
          },
          ...styles
        }}
      >
        {icon && icon}
        <Typography
          variant={titleVariant || 'subtitle2'}
          color={color || '#FFFFFF'}
          sx={{
            textTransform: 'none',
            ...titleStyles
          }}
        >
          {title}
        </Typography>
      </MuiButton>
    </Box>
  );
};

export default Button;
