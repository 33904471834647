export const QBIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1149_13757)">
        <path
          d="M20 40C31.0456 40 40 31.0456 40 20C40 8.95437 31.0456 0 20 0C8.95437 0 0 8.95437 0 20C0 31.0456 8.95437 40 20 40Z"
          fill="#2CA01C"
        />
        <path
          d="M5.555 20.0001C5.555 22.0629 6.37445 24.0412 7.83307 25.4998C9.29169 26.9585 11.27 27.7779 13.3328 27.7779H14.4438V24.8888H13.3328C12.6875 24.894 12.0476 24.7714 11.4499 24.528C10.8522 24.2846 10.3086 23.9253 9.85048 23.4708C9.39235 23.0163 9.02872 22.4756 8.78057 21.8799C8.53242 21.2842 8.40466 20.6453 8.40466 19.9999C8.40466 19.3546 8.53242 18.7157 8.78057 18.12C9.02872 17.5242 9.39235 16.9836 9.85048 16.5291C10.3086 16.0746 10.8522 15.7153 11.4499 15.4719C12.0476 15.2285 12.6875 15.1059 13.3328 15.111H15.9994V30.2223C15.9996 30.9884 16.304 31.723 16.8457 32.2648C17.3874 32.8065 18.122 33.111 18.8881 33.1113V12.2223H13.3328C12.3114 12.2222 11.3 12.4234 10.3563 12.8142C9.41259 13.2051 8.55514 13.778 7.83287 14.5002C7.1106 15.2225 6.53766 16.0799 6.14677 17.0236C5.75588 17.9673 5.555 18.9787 5.555 20.0001ZM26.6659 12.2223H25.555V15.1113H26.6659C27.9529 15.1259 29.1823 15.6474 30.0872 16.5626C30.9922 17.4778 31.4997 18.713 31.4997 20.0001C31.4997 21.2872 30.9922 22.5224 30.0872 23.4376C29.1823 24.3528 27.9529 24.8743 26.6659 24.8888H23.9994V9.77822C23.9994 9.39884 23.9247 9.02316 23.7796 8.67265C23.6344 8.32213 23.4216 8.00364 23.1534 7.73537C22.8851 7.46709 22.5667 7.25428 22.2162 7.10908C21.8657 6.96389 21.49 6.88916 21.1106 6.88916V27.7782H26.6659C28.7287 27.7782 30.7071 26.9588 32.1657 25.5002C33.6243 24.0415 34.4438 22.0632 34.4438 20.0004C34.4438 17.9376 33.6243 15.9593 32.1657 14.5007C30.7071 13.042 28.7287 12.2223 26.6659 12.2223Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1149_13757">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
