import { Box, Button, Paper, Typography } from '@mui/material';
import { PrimaryButton } from '../ui';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import paymentMethodIcon from '@/assets/icons/payment-method.svg';
import { PlanTag } from './planTag';
import { EditCardModal } from '@/pages/account/editCardModal';
import { useState } from 'react';

export const PaymentMethod = () => {
  const [isOpenEditCardModal, setIsOpenEditCardModal] = useState(false);

  return (
    <>
      <Paper
        sx={{
          border: '1px solid #EAECF0',
          borderRadius: '12px',
          width: '100%',
          boxShadow: '0px 2px 4px -2px #1018280F, 0px 4px 8px -2px #1018281A',
          fontFamily: 'Manrope'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '24px 24px 20px 24px',
            borderBottom: '1px solid #EAECF0'
          }}
        >
          <Typography
            sx={{
              fontWeight: '600',
              fontSize: '20px'
            }}
          >
            Payment method
          </Typography>
          <PrimaryButton
            // onClick={handleOpen}
            label="Add new"
            startIcon={<AddOutlinedIcon fontSize="small" />}
          />
        </Box>
        <Box
          sx={{
            padding: '24px 20px'
          }}
        >
          <Box
            sx={{
              border: '1px solid #EAECF0',
              borderRadius: '12px',
              padding: '20px'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'space-between'
              }}
            >
              <img src={paymentMethodIcon} alt="paymentMethodIcon" />
              <Box
                sx={{
                  display: 'flex',
                  gap: '16px'
                }}
              >
                <Button
                  onClick={() => setIsOpenEditCardModal(true)}
                  sx={{
                    width: '116px',
                    height: '44px',
                    backgroundColor: '#D4D7DB52',
                    border: '1px solid #D4D7DB52'
                  }}
                >
                  <Typography
                    sx={{
                      textTransform: 'none',
                      color: '#393A3D',
                      fontWeight: '600',
                      fontSize: '14px'
                    }}
                  >
                    Edit
                  </Typography>
                </Button>
                <Button
                  sx={{
                    width: '116px',
                    height: '44px',
                    backgroundColor: '#FFDADA',
                    border: '1px solid #FFBFBF'
                  }}
                >
                  <Typography
                    sx={{
                      textTransform: 'none',
                      color: '#FF6363',
                      fontWeight: '600',
                      fontSize: '14px'
                    }}
                  >
                    Delete
                  </Typography>
                </Button>
              </Box>
            </Box>
            <Box
              sx={{
                marginTop: '45px'
              }}
            >
              <PlanTag label="Primary" style={{ backgroundColor: '#ebebeb' }} />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'flex-end',
                  marginTop: '12px'
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      color: '#151618',
                      fontSize: '20px',
                      fontWeight: '600'
                    }}
                  >
                    Edward Wake
                  </Typography>
                  <Typography
                    sx={{
                      color: '#747A82',
                      fontSize: '14px',
                      fontWeight: '400',
                      marginTop: '10px'
                    }}
                  >
                    **** **** **** 9865
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    color: '#747A82',
                    fontSize: '14px',
                    fontWeight: '400'
                  }}
                >
                  Card expires at 12/24
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Paper>
      <EditCardModal isOpen={isOpenEditCardModal} onClose={() => setIsOpenEditCardModal(false)} />
    </>
  );
};
