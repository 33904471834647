import { Avatar, Box, Button, Modal, Paper, Stack, Typography } from '@mui/material';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';

import useSession from '@/hooks/useSession';
import TrashIcon from '@/components/icons/Trash';
import { ConfirmDelete } from '@/components';
import { useState } from 'react';
import { useAuth } from '@/contexts/AuthContext';
import { logEventAnalytics } from '@/analytics';

const General = () => {
  const { user } = useSession();
  const { signOut } = useAuth();
  const [isOpenModalDeleteAccount, setIsOpenModalDeleteAccount] = useState<boolean>(false);

  const onHandlerConfirmDelete = () => {
    logEventAnalytics('User', 'Delete Account');
    setIsOpenModalDeleteAccount(false);
  };

  return (
    <>
      <Paper
        elevation={9}
        sx={{
          width: '100%',
          minHeight: '380px',
          padding: '0px 0px 32px 0px',
          gap: 32,
          borderRadius: '12px',
          border: '1px solid transparent',
          overflow: 'hidden',
          position: 'relative'
        }}
      >
        <Stack>
          <Box
            alt="logo"
            component="img"
            src="./assets/background.svg"
            display={{
              xs: 'none',
              md: 'block',
              objectFit: 'cover',
              height: '150px'
            }}
          />
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
              position: 'absolute',
              left: '50%',
              transform: 'translate(-50%, 40%)',
              '@media (max-width: 768px)': {
                transform: 'translate(-50%, 15%)'
              }
            }}
          >
            <Avatar src={user?.picture} sx={{ height: '100px', width: '100px' }} />
            <Typography variant="h5" sx={{ fontSize: '20px', mt: 2 }}>
              {user?.nickname}
            </Typography>
            <Typography variant="body1" sx={{ mb: 4, mt: 1 }}>
              {user?.email}
            </Typography>
            <Stack direction={{ xs: 'column', sm: 'row' }} gap={3}>
              <Button
                sx={{
                  p: '8px 16px 8px 12px',
                  borderRadius: '12px',
                  backgroundColor: '#FFDADA',
                  border: '1px solid #FFBFBF',
                  color: '#FF6363'
                }}
                onClick={() => setIsOpenModalDeleteAccount(true)}
              >
                <TrashIcon />
                <Typography
                  sx={{ ml: 1, fontSize: '14px', fontWeight: 600, textTransform: 'none' }}
                >
                  Delete account
                </Typography>
              </Button>
              <Button
                sx={{
                  p: '8px 16px 8px 12px',
                  borderRadius: '12px',
                  backgroundColor: '#0078C8',
                  color: 'white',
                  minWidth: '172px',
                  '&:hover': { backgroundColor: '#0078C829', color: 'white' }
                }}
                onClick={signOut}
              >
                <LogoutOutlinedIcon />
                <Typography
                  sx={{ ml: 1, fontSize: '14px', fontWeight: 600, textTransform: 'none' }}
                >
                  Log out
                </Typography>
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Paper>

      <Modal
        open={isOpenModalDeleteAccount}
        onClose={() => setIsOpenModalDeleteAccount(false)}
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <ConfirmDelete
          title="Are you sure you want to delete your account?"
          description="Are you sure you want to delete your account?"
          confirmLabel="Delete"
          cancelLabel="Apply"
          style={{
            container: {
              backgroundColor: 'white',
              borderRadius: '12px'
            },
            dangerButton: {
              backgroundColor: '#D4D7DB52',
              border: 'none',
              '&:hover': {
                backgroundColor: '#D4D7DB52',
                border: 'none'
              }
            },
            neutralButton: {
              backgroundColor: '#0078C8',
              color: 'white',
              '&:hover': {
                backgroundColor: '#0078C8'
              }
            },
            stroke: '#0078C8'
          }}
          onConfirm={() => onHandlerConfirmDelete()}
          onCancel={() => setIsOpenModalDeleteAccount(false)}
        />
      </Modal>
    </>
  );
};

export default General;
