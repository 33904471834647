import * as React from 'react';
import { PropsWithChildren } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import GlobalStyles from '@mui/material/GlobalStyles';
import MainNav from './MainNav';
import SideNav from './SideNav';
import { GoogleOAuthProvider } from '@react-oauth/google';
import useSession from '@/hooks/useSession';
import PageLoader from '@/components/PageLoader/PageLoader';

type LayoutProps = {
  title: string;
};

export default function DashboardLayout({
  children,
  title
}: PropsWithChildren<LayoutProps>): React.JSX.Element {
  const { initFlow } = useSession();

  return (
    <GoogleOAuthProvider
      clientId={'311349960961-nf0mno17e57mimgugfhrq5et23ki93fi.apps.googleusercontent.com'}
      // clientId="540530631437-plb1u5d6bc03ere65ohjipae9msan2d1.apps.googleusercontent.com"
    >
      <GlobalStyles
        styles={{
          body: {
            '--MainNav-height': '56px',
            '--MainNav-zIndex': 1000,
            '--SideNav-width': '280px',
            '--SideNav-zIndex': 1100,
            '--MobileNav-width': '320px',
            '--MobileNav-zIndex': 1100
          }
        }}
      />
      {initFlow?.isFirstConnect && <PageLoader />}
      <Box
        sx={{
          bgcolor: 'var(--mui-palette-background-default)',
          display: initFlow?.isFirstConnect ? 'none' : 'flex',
          flexDirection: 'column',
          position: 'relative',
          minHeight: '100%'
        }}
      >
        <SideNav />
        <Box
          sx={{
            display: 'flex',
            flex: '1 1 auto',
            flexDirection: 'column',
            pl: { lg: 'var(--SideNav-width)' }
          }}
        >
          <MainNav title={title} />
          <main>
            <Container maxWidth="xl" sx={{ pb: '64px', pt: '32px' }}>
              {children}
            </Container>
          </main>
        </Box>
      </Box>
    </GoogleOAuthProvider>
  );
}
