import { useSelector } from 'react-redux';
import { sessionSelectors } from '@/slices';

function useSession() {
  const session = useSelector(sessionSelectors.session);

  return session || {};
}

export const useGetCurrentWorkSpace = () => {
  const session = useSelector(sessionSelectors.session);
  const workSpaces = session?.workspaces || [];

  return workSpaces.find(workSpace => workSpace.id === session?.currentWorkspaceId) || undefined;
}

export function useUserEmail() {
  const session = useSession();

  return session?.user?.email || '';
}

export function useAuthInfo() {
  const session = useSession();
  const fullName = `${session?.user?.given_name} ${session?.user?.family_name}`;
  const isLoggedInAndVerified = !!session?.user && !!session?.user.updated_at;
  const isNotLoggedIn = !session?.user;

  return {
    fullName,
    isLoggedInAndVerified,
    isNotLoggedIn
  };
}

export default useSession;
