import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  ActiveButton,
  CheckBoxInput,
  ContentWrapper,
  OutlinedLightButton,
  SearchInput,
  SelectInput
} from '@/components';
import { Box, Stack, Typography } from '@mui/material';
import BaseDatePicker from '../../components/ui/form/baseDatePicker/BaseDatePicker';
import {
  ParameterValuesBodyStyled,
  ParameterValuesFooterStyled,
  ParameterValuesStyled
} from './parameterValues.styled';
import PrimaryButton from '../ui/primaryButton/PrimaryButton';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { PARAMETER_DATA_TYPES, PARAMETER_UI_ELEMENTS } from '@/components/parameterValues/const';
import CustomButtonGroup from '../selectReportingMethod/сustomButtonGroup';
import {
  ErrorField,
  ParameterValueApply,
  ParameterValuesProps,
  TemplateParameterWithValue
} from './type';
import { ReportTemplateApiTab } from '@/services/api/reportTemplates/types';

const REQUIRED_ERROR = 'Field is required';

const ParameterValues: FC<ParameterValuesProps> = ({
  onBack,
  isBack,
  styleConfig,
  defaultName = '',
  defaulValue,
  onApply,
  renderParameter,
  parametersTabsValuesRender
}) => {
  const [form, setForm] = useState<ParameterValueApply>({
    reportName: defaultName,
    startDate: defaulValue?.startDate || new Date(),
    endDate: defaulValue?.endDate || new Date()
  });
  const [errors, setErrors] = useState<ErrorField>({ reportName: '' });
  const [parameterValues, setParameterValues] =
    useState<Record<string, TemplateParameterWithValue>>(renderParameter);
  const [parameterValueTabs, setParameterValueTabs] = useState<ReportTemplateApiTab[]>(
    parametersTabsValuesRender || []
  );

  const parameterValuesList = Object.values(parameterValues);

  const validate = () => {
    const errorValidation: ErrorField = {};
    if (!form.reportName) {
      errorValidation.reportName = REQUIRED_ERROR;
    }

    parameterValuesList.forEach(parameter => {
      if (parameter.required && !parameter.value) {
        errorValidation[parameter.key] = REQUIRED_ERROR;
      }
    });

    // Валідація параметрів у табах
    parameterValueTabs.forEach((tab, tabIndex) => {
      const tabParameters = Object.values(tab.templateParametersValuesRender || {});
      tabParameters.forEach(parameter => {
        if (parameter.required && !parameter.value) {
          const errorKey = `tab${tabIndex}_${parameter.key}`;
          errorValidation[errorKey] = REQUIRED_ERROR;
        }
      });
    });

    setErrors(errorValidation);

    return errorValidation;
  };

  const onSubmit = () => {
    const errorValidation = validate();

    if (onApply && !Object.keys(errorValidation).length) {
      onApply(form, parameterValuesList, parameterValueTabs);

      // Зібрати всі значення параметрів
      // const allParameterValues = [...parameterValuesList];

      // parameterValueTabs.forEach(tab => {
      //   const tabParameters = Object.values(tab.templateParametersValuesRender || {});
      //   allParameterValues.push(...tabParameters);
      // });

      // console.log(allParameterValues);

      // onApply(form, allParameterValues);
    }
  };

  const boxStyles = {
    display: 'grid',
    gridTemplateColumns: { xs: '1fr', sm: 'repeat(2, 1fr)' },
    gap: '2rem'
  };

  const onHandleChangeParameterValues = (
    key: string,
    value: string | boolean,
    tabIndex?: number
  ) => {
    if (typeof tabIndex === 'number') {
      // Оновити параметр у табі
      setParameterValueTabs(prevTabs => {
        const updatedTabs = [...prevTabs];
        const tab = updatedTabs[tabIndex];
        if (tab.templateParametersValuesRender && tab.templateParametersValuesRender[key]) {
          tab.templateParametersValuesRender[key] = {
            ...tab.templateParametersValuesRender[key],
            value
          };
        }
        return updatedTabs;
      });
    } else {
      // Оновити параметр в основній формі
      setParameterValues(prevState => ({
        ...prevState,
        [key]: {
          ...prevState[key],
          value
        }
      }));
    }
  };

  const customDownButtons = useMemo(() => {
    return styleConfig?.customDownButtons || [];
  }, [styleConfig?.customDownButtons]);

  const getInputType = (dataType: string) => {
    const dataTypes = {
      [PARAMETER_DATA_TYPES.STRING]: 'text',
      [PARAMETER_DATA_TYPES.INTEGER]: 'number',
      [PARAMETER_DATA_TYPES.DOUBLE]: 'number',
      [PARAMETER_DATA_TYPES.DATE]: 'date',
      [PARAMETER_DATA_TYPES.BOOLEAN]: 'checkbox'
    };
    return dataTypes[dataType];
  };

  const renderField = (param: TemplateParameterWithValue, errorKey?: string, tabIndex?: number) => {
    const key = errorKey || param.key;
    const errorMessage = errors[key];

    switch (param.uiElement) {
      case PARAMETER_UI_ELEMENTS.DROPDOWN:
        return (
          <SelectInput
            id={param.key}
            key={param.key}
            label={param.name}
            placeholder={param.value as string}
            options={param.valueOptions}
            onChange={value => onHandleChangeParameterValues(param.key, value, tabIndex)}
            isError={!!errorMessage}
          />
        );
      case PARAMETER_UI_ELEMENTS.INPUT:
        return param.dataType === PARAMETER_DATA_TYPES.DATE ? (
          <BaseDatePicker
            key={param.key}
            errorMessage={errors[param.key]}
            value={new Date(param.value as string)}
            onChange={value =>
              onHandleChangeParameterValues(param.key, value?.toISOString() ?? '', tabIndex)
            }
            label={param.name}
          />
        ) : (
          <SearchInput
            inputStyle={{ width: '100%' }}
            key={param.key}
            title={param.name}
            defaultValue={param.value as string}
            onChange={value => onHandleChangeParameterValues(param.key, value, tabIndex)}
            isError={!!errorMessage}
            type={getInputType(param.dataType)}
            isNotShowSearchIcon
          />
        );
      case PARAMETER_UI_ELEMENTS.SWITCH:
        return (
          <Stack direction="column" gap="1rem">
            <Typography
              sx={{
                textAlign: 'start',
                width: '100%',
                fontSize: { xs: '12px', sm: '14px' },
                color: errors[param.key] ? 'rgb(244, 67, 54)' : '#393A3DA3'
              }}
              variant="h5"
            >
              {param.name}
            </Typography>
            {!param.valueOptions.length && <Typography>Mocked options displayed</Typography>}
            <Box sx={boxStyles}>
              {(param.valueOptions.length ? param.valueOptions : []).map(option => {
                return (
                  <ActiveButton
                    key={option.value}
                    label={option.name || option.value}
                    active={param.value === option.value}
                    onClick={() => onHandleChangeParameterValues(param.key, option.value, tabIndex)}
                  />
                );
              })}
            </Box>
          </Stack>
        );
      case PARAMETER_UI_ELEMENTS.CHECKBOX:
        return (
          <Stack direction="column" justifyContent="center" gap="1rem">
            <Box sx={boxStyles}>
              <CheckBoxInput
                key={`${param.name}-checkbox`}
                label={param.name}
                defaultValue={param.value === 'true'}
                onChecked={isChecked =>
                  onHandleChangeParameterValues(param.key, isChecked, tabIndex)
                }
                isError={!!errorMessage}
              />
            </Box>
          </Stack>
        );
      default:
        return <Typography>uiElement is not provided</Typography>;
    }
  };

  const renderFields = useCallback(() => {
    const chunks = [];
    for (let i = 1; i < parameterValuesList.length; i += 2) {
      chunks.push(parameterValuesList.slice(i, i + 2));
    }

    if (!chunks.length) {
      return <></>;
    }

    return chunks.map((chunk, index) => (
      <Stack direction="column" gap="1rem" key={index}>
        <Box sx={boxStyles}>
          {chunk.map((field, idx) => (
            <React.Fragment key={`${idx}-key-render`}>{renderField(field)}</React.Fragment>
          ))}
        </Box>
      </Stack>
    ));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parameterValues, errors]);

  const renderFieldsTabs = (
    parameterValuesListProps: TemplateParameterWithValue[],
    tabIndex: number
  ) => {
    const chunks = [];
    for (let i = 0; i < parameterValuesListProps.length; i += 2) {
      chunks.push(parameterValuesListProps.slice(i, i + 2));
    }

    if (!chunks.length) {
      return <></>;
    }

    return chunks.map((chunk, index) => (
      <Stack direction="column" gap="1rem" key={index}>
        <Box sx={boxStyles}>
          {chunk.map((field, idx) => (
            <React.Fragment key={`${idx}-key-render`}>
              {renderField(field, `tab${tabIndex}_${field.key}`, tabIndex)}
            </React.Fragment>
          ))}
        </Box>
      </Stack>
    ));
  };

  return (
    <Box sx={{ mt: styleConfig?.isNotMarginTop ? '0' : '2rem' }}>
      <ContentWrapper
        title={styleConfig?.isNotHeaderTitle ? undefined : 'Parameter values'}
        isNotBorder={styleConfig?.isNotBorder}
      >
        <ParameterValuesStyled>
          <ParameterValuesBodyStyled>
            <Stack direction="column" gap="1.5rem">
              {/* date select group */}
              <Stack direction="column" gap="1rem">
                <Box sx={boxStyles}>
                  <BaseDatePicker
                    value={form.startDate}
                    onChange={value => setForm({ ...form, startDate: value })}
                    label="From"
                  />
                  <BaseDatePicker
                    value={form.endDate}
                    onChange={value => setForm({ ...form, endDate: value })}
                    label="To"
                  />
                </Box>
              </Stack>

              {/* select input group */}
              <Stack direction="column" gap="1rem">
                <Box sx={boxStyles}>
                  <SearchInput
                    title="Report Name"
                    inputStyle={{ width: '100%' }}
                    isNotShowSearchIcon
                    defaultValue={form.reportName}
                    onChange={value => setForm({ ...form, reportName: value })}
                    isError={!!errors.reportName}
                  />
                  {!!parameterValuesList.length && renderField(parameterValuesList[0])}
                  {/* <SelectInput {...selectData.first} /> */}
                </Box>
              </Stack>
              {renderFields()}
            </Stack>

            {parameterValueTabs.map((tab, tabIndex) => {
              const parameterValuesTabList = Object.values(
                tab.templateParametersValuesRender || {}
              );

              return (
                <Stack direction="column" gap="1.5rem" key={`${tabIndex}-${tab.id}`}>
                  <Box
                    sx={{
                      minHeight: '5rem',
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    <Typography variant="h2" sx={{ fontSize: { xs: '20px', sm: '24px' } }}>
                      Tab {tabIndex + 1}
                    </Typography>
                  </Box>
                  {renderFieldsTabs(parameterValuesTabList, tabIndex)}
                </Stack>
              );
            })}
          </ParameterValuesBodyStyled>
          <ParameterValuesFooterStyled>
            {!customDownButtons.length && (
              <Stack direction="row" justifyContent="flex-end" sx={{ gap: '0.75rem' }}>
                {isBack && (
                  <OutlinedLightButton
                    label="Back"
                    startIcon={
                      <ArrowBackIcon
                        sx={{
                          color: '#747A82'
                        }}
                      />
                    }
                    onClick={onBack}
                  />
                )}
                <PrimaryButton onClick={onSubmit} label="Next" endIcon={<ArrowForwardIcon />} />
              </Stack>
            )}
            <CustomButtonGroup
              customDownButtons={customDownButtons}
              value={{
                form: form,
                parameterValueTabs: parameterValueTabs
              }}
              parameters={parameterValuesList}
              onValidate={validate}
            />
          </ParameterValuesFooterStyled>
        </ParameterValuesStyled>
      </ContentWrapper>
    </Box>
  );
};

export default ParameterValues;
