import { useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Link as MUILink,
  TextField,
  Typography
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import useHandleFormApiErrors from '@/hooks/useHandleFormApiErrors';
import useSession from '@/hooks/useSession';
import { useAuth } from '@/contexts/AuthContext/AuthContextProvider';
import { paths } from '@/utils/paths';
import { config } from '@/config';
import { AuthLayout } from '@/components/auth/Layout';
import { useAlert } from '@/contexts/AlertContext';
import AuthWrapper from '@/components/auth/AuthWrapper';
import { FormValues } from './types';

export default function LoginPage(): JSX.Element {
  const { user } = useSession();
  const navigate = useNavigate();
  const { setAlert } = useAlert();

  const [searchParams] = useSearchParams();
  const invitationId = searchParams.get('invitationId');
  const redirectUri = searchParams.get('redirectUri');
  const message = searchParams.get('message');
  const messageCode = searchParams.get('code');

  useEffect(() => {
    if (redirectUri) {
      localStorage.setItem('redirectUri', redirectUri);
      if (user) {
        return navigate(redirectUri, { replace: true });
      }
    } else if (user) {
      navigate('/', { replace: true });
    }
  }, [invitationId, redirectUri, user, navigate]);

  useEffect(() => {
    if (message) {
      if (messageCode === 'success') {
        setAlert({
          type: 'success',
          description: message
        });
      } else {
        setAlert({
          type: 'error',
          description: message
        });
      }
      navigate(paths.auth.signIn, { replace: true });
    }
  }, [message, messageCode, setAlert, navigate]);

  const handleFormApiErrors = useHandleFormApiErrors();
  const { loginWithEmailPassword } = useAuth();

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Email is required'),
    password: Yup.string()
      .min(6, 'Password must be at least 6 characters')
      .required('Password is required')
  });

  const handleSubmit = useCallback(
    async (values: FormValues, { setErrors }: any) => {
      try {
        const res = await loginWithEmailPassword(values.email, values.password);
        console.log('res: ', res);
      } catch (error: any) {
        console.log('error: ', error);
        handleFormApiErrors({
          defaultMessage: error.error_description,
          error: {},
          setErrors,
          showFieldErrorsAsToast: true
        });
      }
    },
    [handleFormApiErrors, loginWithEmailPassword]
  );

  const formik = useFormik({
    validateOnBlur: false,
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema,
    onSubmit: handleSubmit
  });

  return (
    <>
      <Helmet>
        <title>Sign in | Auth | {config.site.name}</title>
      </Helmet>
      <AuthLayout>
        <Typography variant="h4" sx={{ textAlign: 'center' }} gutterBottom>
          Log in
        </Typography>
        <Typography justifyContent={'center'} sx={{ fontSize: { xs: '14px', sm: '16px' } }}>
          Don't have an account?{' '}
          <Link to={paths.auth.signUp} style={{ boxShadow: 'none' }}>
            <MUILink sx={{ textDecoration: 'none', color: '#0078C8', fontWeight: 600 }}>
              Sign up
            </MUILink>
          </Link>
        </Typography>

        <AuthWrapper>
          <form noValidate onSubmit={formik.handleSubmit}>
            <TextField
              variant="standard"
              autoFocus
              error={Boolean(formik.touched.email && formik.errors.email)}
              fullWidth
              helperText={formik.touched.email && formik.errors.email}
              label="Email Address"
              margin="normal"
              name="email"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type="email"
              value={formik.values.email}
              InputProps={{ sx: { borderRadius: '12px' } }}
            />
            <TextField
              variant="standard"
              error={Boolean(formik.touched.password && formik.errors.password)}
              fullWidth
              helperText={formik.touched.password && formik.errors.password}
              label="Password"
              margin="normal"
              name="password"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type="password"
              value={formik.values.password}
              InputProps={{ sx: { borderRadius: '12px' } }}
            />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <FormControlLabel control={<Checkbox />} label="Remember me" />
              <Link to={paths.auth.forgotPassword}>
                <MUILink
                  sx={{
                    width: '100%',
                    textAlign: 'end',
                    display: 'inline-block',
                    textDecoration: 'none',
                    fontWeight: 400,
                    fontSize: '12px'
                  }}
                  variant="body1"
                >
                  Forgot password?
                </MUILink>
              </Link>
            </Box>
            <Button
              type="submit"
              fullWidth
              disabled={formik.isSubmitting}
              variant="contained"
              sx={{
                my: 2,
                py: '10px',
                textTransform: 'none',
                borderRadius: '12px',
                backgroundColor: '#0078C8',
                '&:hover': { backgroundColor: '#0B789B' }
              }}
            >
              <Typography variant="subtitle2">Log in</Typography>
            </Button>
          </form>
        </AuthWrapper>
      </AuthLayout>
    </>
  );
}
