export const XeroOldIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.998 39.9238C31.0016 39.9238 39.9217 31.0037 39.9217 20.0001C39.9217 8.9965 31.0016 0.0763435 19.998 0.0763435C8.99438 0.0763435 0.0742188 8.9965 0.0742188 20.0001C0.0742188 31.0037 8.99438 39.9238 19.998 39.9238Z"
        fill="#1AB4D7"
      />
      <path
        d="M9.61231 19.9424L13.0125 16.5339C13.1252 16.419 13.1881 16.2669 13.1881 16.1055C13.1881 15.7693 12.9148 15.4963 12.5786 15.4963C12.4148 15.4963 12.2613 15.5605 12.1455 15.6777C12.1452 15.6784 8.74841 19.0732 8.74841 19.0732L5.33613 15.6726C5.22083 15.5589 5.06805 15.4963 4.90604 15.4963C4.57025 15.4963 4.29688 15.7692 4.29688 16.1051C4.29688 16.2689 4.36229 16.4226 4.47969 16.5385L7.87936 19.9377L4.48145 23.342C4.36201 23.4596 4.29688 23.6136 4.29688 23.7782C4.29688 24.1144 4.57025 24.387 4.90604 24.387C5.06832 24.387 5.22124 24.3241 5.33613 24.2091L8.74313 20.8067L12.1372 24.1967C12.2576 24.3212 12.4129 24.3875 12.5786 24.3875C12.9144 24.3875 13.1878 24.1144 13.1878 23.7782C13.1878 23.616 13.1249 23.4636 13.0106 23.3487L9.61231 19.9424Z"
        fill="white"
      />
      <path
        d="M29.8242 19.9415C29.8242 20.552 30.3206 21.0486 30.9318 21.0486C31.5416 21.0486 32.0382 20.552 32.0382 19.9415C32.0382 19.331 31.5416 18.8344 30.9318 18.8344C30.3206 18.8344 29.8242 19.331 29.8242 19.9415Z"
        fill="white"
      />
      <path
        d="M27.726 19.9421C27.726 18.1743 29.1641 16.7359 30.9321 16.7359C32.6992 16.7359 34.1377 18.1743 34.1377 19.9421C34.1377 21.7095 32.6992 23.1472 30.9321 23.1472C29.1641 23.1472 27.726 21.7095 27.726 19.9421ZM26.4648 19.9421C26.4648 22.4049 28.4689 24.4087 30.9321 24.4087C33.3953 24.4087 35.4003 22.4049 35.4003 19.9421C35.4003 17.4789 33.3953 15.4746 30.9321 15.4746C28.4689 15.4746 26.4648 17.4789 26.4648 19.9421Z"
        fill="white"
      />
      <path
        d="M26.1463 15.5511L25.9589 15.5505C25.3962 15.5505 24.8535 15.728 24.4003 16.0773C24.3405 15.804 24.0963 15.5983 23.8053 15.5983C23.4703 15.5983 23.202 15.8667 23.2012 16.2023C23.2012 16.2035 23.2032 23.7262 23.2032 23.7262C23.2041 24.0612 23.4773 24.3335 23.8124 24.3335C24.1475 24.3335 24.4207 24.0612 24.4215 23.7255C24.4215 23.724 24.4218 19.0992 24.4218 19.0992C24.4218 17.5573 24.5628 16.9345 25.8836 16.7695C26.0057 16.7543 26.1385 16.7567 26.139 16.7567C26.5005 16.7443 26.7573 16.4959 26.7573 16.1602C26.7573 15.8244 26.4831 15.5511 26.1463 15.5511Z"
        fill="white"
      />
      <path
        d="M14.4489 19.2066C14.4489 19.1899 14.4502 19.1725 14.451 19.1554C14.8047 17.7572 16.071 16.7226 17.579 16.7226C19.1053 16.7226 20.3832 17.7826 20.7188 19.2066H14.4489ZM21.9663 19.0915C21.7038 17.8486 21.0233 16.8276 19.9871 16.172C18.4724 15.2103 16.4723 15.2635 15.0092 16.3039C13.8158 17.1529 13.127 18.5417 13.127 19.9724C13.127 20.3311 13.1702 20.6932 13.2602 21.0496C13.7108 22.8223 15.2347 24.1642 17.0516 24.3866C17.5908 24.4518 18.1155 24.4206 18.659 24.2799C19.1259 24.1663 19.5778 23.9769 19.9941 23.7101C20.4262 23.4323 20.7872 23.066 21.1369 22.6275C21.1439 22.6195 21.151 22.6123 21.1581 22.6039C21.4007 22.3029 21.3557 21.8749 21.0891 21.6706C20.8642 21.4982 20.4866 21.4284 20.1895 21.8088C20.1257 21.8998 20.0544 21.9934 19.976 22.087C19.7398 22.348 19.4467 22.6009 19.0955 22.7971C18.6486 23.0358 18.1393 23.1723 17.5979 23.1753C15.826 23.1556 14.8777 21.9187 14.5404 21.0358C14.4815 20.871 14.4361 20.7 14.4045 20.5239C14.4003 20.4909 14.3973 20.4591 14.3961 20.4298C14.7624 20.4298 20.755 20.4288 20.755 20.4288C21.6267 20.4105 22.0959 19.7952 21.9663 19.0915Z"
        fill="white"
      />
    </svg>
  );
};
