export enum EAccountingReportsApiPaths {
  REPORTS = '/report'
}

export enum EReportTemplatePaths {
  REPORT_TEMPLATE = '/report-template'
}

export enum ECompanyPaths {
  USER_COMPANIES = '/company/UserCompanies',
  COMPANY = '/company'
}

export enum QuickBookPaths {
  QUICKBOOK_CONNECT = '/quickbooks/connectCompany',
  QUICKBOOK_LOGIN = '/quickbooks/login'
}

export enum EAccountingReportTemplatesApiPaths {
  REPORT_TEMPLATES = '/report-template/getAllPaginated'
}

export enum EReportsTags {
  REPORT = 'REPORT'
}

export enum EAdditionalReportsTags {
  REPORT_LIST = 'REPORT_LIST'
}

export enum QuickBookTags {
  QUICKBOOK = 'QUICKBOOK'
}

export enum CompanyTags {
  COMPANY = 'COMPANY',
  COMPANY_LIST = 'COMPANY_LIST'
}

export enum EUserPaths {
  INFO = '/user/info',
  GOOGLE_CONNECT = '/google/connect'
}

export enum EWorspacesPaths {
  WORKSPACE = '/workspace',
  ASSUME_ROLE = '/workspace/assumeRole'
}

export enum EInvitationPaths {
  INVITATION = '/invitation',
  ACCEPT = '/invitation/accept'
}
