export const UpgradePlanIcon = ({ fill, ...props }: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="64.000000"
      height="58.000000"
      viewBox="0 0 64 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <desc>Created with Pixso.</desc>
      <defs />
      <path
        id="Icon"
        d="M32 21.2C32 14.47 32 11.11 33.3 8.55C34.45 6.29 36.29 4.45 38.55 3.3C41.11 2 44.48 2 51.19 2L52.39 2C55.76 2 57.44 2 58.72 2.65C59.85 3.22 60.76 4.14 61.34 5.27C62 6.55 62 8.23 62 11.6L62 37.39C62 40.76 62 42.44 61.34 43.72C60.76 44.85 59.85 45.77 58.72 46.34C57.44 47 55.76 47 52.39 47L48.27 47C44.51 47 42.64 47 40.93 47.51C39.42 47.97 38.01 48.72 36.8 49.72C35.42 50.86 34.38 52.42 32.3 55.54L32 56L31.69 55.54C29.61 52.42 28.57 50.86 27.19 49.72C25.98 48.72 24.57 47.97 23.06 47.51C21.35 47 19.48 47 15.72 47L11.6 47C8.23 47 6.55 47 5.27 46.34C4.14 45.77 3.23 44.85 2.65 43.72C2 42.44 2 40.76 2 37.39L2 11.6C2 8.23 2 6.55 2.65 5.27C3.23 4.14 4.14 3.22 5.27 2.65C6.55 2 8.23 2 11.6 2L12.8 2C19.51 2 22.88 2 25.44 3.3C27.7 4.45 29.54 6.29 30.69 8.55C32 11.11 32 14.47 32 21.2L32 56"
        stroke={fill ?? 'currentColor'}
        stroke-opacity="1.000000"
        stroke-width="4.000000"
        stroke-linejoin="round"
      />
    </svg>
  );
};
