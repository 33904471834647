import { CurrentPlan } from '@/components/planComponents/currentPlan';
import { PaymentMethod } from '@/components/planComponents/paymentMethod';
import { Box, createTheme, ThemeProvider } from '@mui/material';
import { PreviousChargeTable } from './previousChargeTable';

function Plan() {
  const theme = createTheme({
    typography: {
      fontFamily:
        'Manrope, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif'
    }
  });

  return (
    <ThemeProvider theme={theme}>
      <Box>
        <Box
          sx={{
            display: 'flex',
            gap: '32px'
          }}
        >
          <CurrentPlan />
          <PaymentMethod />
        </Box>
        <Box
          sx={{
            marginTop: '32px'
          }}
        >
          <PreviousChargeTable />
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default Plan;
